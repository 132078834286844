/*
 *  Web site complete style sheet
 */
/* Generated file */
/*
 *  Visualization basic styles 
 */
@import "@fontsource/josefin-sans/500.css";
@import "@fontsource/josefin-sans/700.css";
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto-mono/400.css";
@import "@fontsource/roboto-mono/700.css";
@import "@fontsource/ubuntu/400.css";
@import "@fontsource/ubuntu/700.css";
@import "site/~@fontsource/lato/400.css";
.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.axis-text-style {
  font-size: 0.9em;
  font-family: inherit;
  fill: rgba(0, 0, 0, 0.6);
}
[data-layout="barChart"] .domain {
  stroke-width: 2;
  stroke: rgba(0, 0, 0, 0.6);
  stroke-opacity: 0.75;
  fill: transparent;
}
[data-layout="barChart"] .tick text {
  font-size: 0.9em !important;
  font-family: inherit !important;
  fill: rgba(0, 0, 0, 0.6) !important;
}
[data-layout="barChart"] .tick line {
  stroke: rgba(0, 0, 0, 0.1);
  shape-rendering: crispEdges;
}
[data-layout="barChart"] rect {
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  fill-opacity: 0.9;
}
[data-layout="barChart"] rect:hover {
  fill-opacity: 1;
}
body > .tooltip {
  background-color: white;
  border: 1px solid #1b1c1d;
  padding: 0.5em;
  width: 25em;
  white-space: normal;
}
body > .tooltip.ui.label {
  line-height: 130%;
  font-size: 100% / 1.07;
  color: #000;
}
body > .tooltip small {
  display: block;
  margin-top: 0.1em;
  margin-bottom: 0;
  line-height: 110%;
}
body > .tooltip span {
  display: inline-block;
  margin: 2px 0;
}
body > .tooltip .name {
  margin-right: 1ex;
}
body > .tooltip .name:after {
  content: ":";
}
body > .tooltip .source {
  display: block;
}
body > .tooltip .note {
  display: block;
}
body > .tooltip .note:before {
  content: "(";
}
body > .tooltip .note:after {
  content: ")";
}
.bizq-divider {
  display: table;
  white-space: nowrap;
  height: auto;
  line-height: 1;
  text-align: center;
  margin: 0 0 1em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 130%;
}
.bizq-divider:before,
.bizq-divider:after {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC");
}
.bizq-divider:before {
  background-position: right 1em top 50%;
}
.bizq-divider:after {
  background-position: left 1em top 50%;
}
[data-layout="vita"] a {
  fill-opacity: 0.9;
  cursor: pointer;
}
[data-layout="vita"] a:hover {
  fill-opacity: 1;
}
[data-layout="vita"] polygon,
[data-layout="vita"] rect {
  fill: #a35956;
}
[data-layout="vita"] polygon.c10,
[data-layout="vita"] rect.c10 {
  fill: #007388;
}
[data-layout="vita"] [data-old="true"] polygon,
[data-layout="vita"] [data-old="true"] rect {
  fill: #888888;
}
[data-layout="vita"] [data-warn="true"] polygon,
[data-layout="vita"] [data-warn="true"] rect {
  fill: #f38b0c;
}
[data-layout="vita"] .label-text {
  fill: white;
  text-anchor: middle;
}
[data-layout="vita"] .extra.left {
  text-anchor: end;
  transform: translateX(-0.3em);
}
[data-layout="vita"] .extra.right {
  text-anchor: start;
  transform: translateX(0.3em);
}
[data-layout="vita"] line.today {
  stroke: #888888;
  stroke-width: 2;
  stroke-dasharray: 2 2;
}
[data-layout="history"],
[data-layout="vita"] {
  font-size: 0.81em;
}
[data-layout="history"] .tick text,
[data-layout="vita"] .tick text {
  font-size: 0.9em;
  font-family: inherit;
  fill: rgba(0, 0, 0, 0.6);
}
[data-layout="history"] .tick line,
[data-layout="vita"] .tick line,
[data-layout="history"] .domain,
[data-layout="vita"] .domain {
  stroke-width: 2;
  stroke: rgba(0, 0, 0, 0.6);
  stroke-opacity: 0.75;
  fill: transparent;
}
[data-layout="history"] .link-layer path[data-highlighted="true"],
[data-layout="vita"] .link-layer path[data-highlighted="true"] {
  stroke-width: 3;
}
[data-layout="history"] .label-g[data-clickable="true"] a,
[data-layout="vita"] .label-g[data-clickable="true"] a,
[data-layout="history"] .label-g a[*|href],
[data-layout="vita"] .label-g a[*|href] {
  cursor: pointer;
}
[data-layout="history"] .label-g[data-clickable="true"] a text,
[data-layout="vita"] .label-g[data-clickable="true"] a text,
[data-layout="history"] .label-g a[*|href] text,
[data-layout="vita"] .label-g a[*|href] text {
  cursor: pointer;
}
[data-layout="history"] .label-g text,
[data-layout="vita"] .label-g text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
[data-layout="history"] .label-g a,
[data-layout="vita"] .label-g a {
  cursor: default;
}
[data-layout="history"] .label-g a rect,
[data-layout="vita"] .label-g a rect {
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="history"] .grid path,
[data-layout="vita"] .grid path {
  display: none !important;
}
[data-layout="drillDown"] .root {
  position: relative;
  height: 100%;
}
[data-layout="drillDown"] * {
  box-sizing: border-box;
}
[data-layout="drillDown"] .legends {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 2px 0 2px;
  font-size: 90%;
}
[data-layout="drillDown"] .legends .legend1 {
  font-weight: bold;
}
[data-layout="drillDown"] .legends .legend3 {
  float: right;
}
[data-layout="drillDown"] .box-parent {
  position: absolute;
  top: 0px;
  bottom: 2em;
  left: 0px;
  right: 0px;
}
[data-layout="drillDown"] .box-parent .box {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  position: absolute;
  padding: 0.3rem;
  color: white;
  font-size: 0.7rem;
  line-height: 1.2;
  text-indent: 2px;
}
[data-layout="drillDown"] .box-parent .box .value {
  font-weight: bold;
  font-size: 0.8rem;
}
[data-layout="drillDown"] .box-parent .box[data-neg="true"] .value,
[data-layout="drillDown"] .box-parent .box[data-neg="true"] .desc {
  color: pink;
}
[data-layout="drillDown"] .box-parent .box[data-small="true"] .value,
[data-layout="drillDown"] .box-parent .box[data-small="true"] .desc {
  display: none;
}
[data-layout="drillDown"] .box-parent.highlighting .box.highlighted {
  opacity: 1;
}
[data-layout="drillDown"] .box-parent.highlighting .box:hover {
  transform: scale(1.05);
  z-index: 100;
  border: 1px solid white;
}
[data-layout="graph"] svg:hover,
[data-layout="dendrogram"] svg:hover {
  overflow-x: visible !important;
}
[data-layout="graph"] svg .legend line,
[data-layout="dendrogram"] svg .legend line {
  stroke: #a35956;
  stroke-width: 2;
}
[data-layout="graph"] svg .legend line[data-old="true"],
[data-layout="dendrogram"] svg .legend line[data-old="true"] {
  stroke: #888888;
}
[data-layout="graph"] svg .legend line[data-warn="true"],
[data-layout="dendrogram"] svg .legend line[data-warn="true"] {
  stroke: #f38b0c;
}
[data-layout="graph"] svg .legend text,
[data-layout="dendrogram"] svg .legend text {
  font-size: 0.8rem;
  font-weight: normal;
  text-anchor: start;
  fill: #a35956;
}
[data-layout="graph"] svg .legend text[data-old="true"],
[data-layout="dendrogram"] svg .legend text[data-old="true"] {
  fill: #888888;
}
[data-layout="graph"] svg .legend text[data-warn="true"],
[data-layout="dendrogram"] svg .legend text[data-warn="true"] {
  fill: #f38b0c;
}
[data-layout="graph"] svg .link .line,
[data-layout="dendrogram"] svg .link .line,
[data-layout="graph"] svg .link .head,
[data-layout="dendrogram"] svg .link .head,
[data-layout="graph"] svg .link .tail,
[data-layout="dendrogram"] svg .link .tail {
  stroke: #a35956;
  transition-property: stroke;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link .head,
[data-layout="dendrogram"] svg .link .head,
[data-layout="graph"] svg .link .tail,
[data-layout="dendrogram"] svg .link .tail {
  fill: #a35956;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link .desc,
[data-layout="dendrogram"] svg .link .desc {
  fill: #a35956;
}
[data-layout="graph"] svg .link:hover .desc,
[data-layout="dendrogram"] svg .link:hover .desc {
  fill: white;
}
[data-layout="graph"] svg .link:hover .desc-bg,
[data-layout="dendrogram"] svg .link:hover .desc-bg {
  fill: #af4e4a;
  opacity: 0.9;
}
[data-layout="graph"] svg .link .line,
[data-layout="dendrogram"] svg .link .line {
  stroke-width: 3;
  fill: transparent;
}
[data-layout="graph"] svg .link .desc,
[data-layout="dendrogram"] svg .link .desc {
  opacity: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  font-size: 0.7rem;
}
[data-layout="graph"] svg .link .desc-bg,
[data-layout="dendrogram"] svg .link .desc-bg {
  fill: white;
  opacity: 0;
}
[data-layout="graph"] svg .link .head,
[data-layout="dendrogram"] svg .link .head,
[data-layout="graph"] svg .link .tail,
[data-layout="dendrogram"] svg .link .tail {
  display: none;
}
[data-layout="graph"] svg .link[data-head] .head,
[data-layout="dendrogram"] svg .link[data-head] .head {
  display: inherit;
}
[data-layout="graph"] svg .link[data-tail] .tail,
[data-layout="dendrogram"] svg .link[data-tail] .tail {
  display: inherit;
}
[data-layout="graph"] svg .link[data-old="true"] .line,
[data-layout="dendrogram"] svg .link[data-old="true"] .line,
[data-layout="graph"] svg .link[data-old="true"] .head,
[data-layout="dendrogram"] svg .link[data-old="true"] .head,
[data-layout="graph"] svg .link[data-old="true"] .tail,
[data-layout="dendrogram"] svg .link[data-old="true"] .tail {
  stroke: #888888;
  transition-property: stroke;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-old="true"] .head,
[data-layout="dendrogram"] svg .link[data-old="true"] .head,
[data-layout="graph"] svg .link[data-old="true"] .tail,
[data-layout="dendrogram"] svg .link[data-old="true"] .tail {
  fill: #888888;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-old="true"] .desc,
[data-layout="dendrogram"] svg .link[data-old="true"] .desc {
  fill: #888888;
}
[data-layout="graph"] svg .link[data-old="true"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-old="true"]:hover .desc {
  fill: white;
}
[data-layout="graph"] svg .link[data-old="true"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-old="true"]:hover .desc-bg {
  fill: #7a7a7a;
  opacity: 0.9;
}
[data-layout="graph"] svg .link[data-warn="true"] .line,
[data-layout="dendrogram"] svg .link[data-warn="true"] .line,
[data-layout="graph"] svg .link[data-warn="true"] .head,
[data-layout="dendrogram"] svg .link[data-warn="true"] .head,
[data-layout="graph"] svg .link[data-warn="true"] .tail,
[data-layout="dendrogram"] svg .link[data-warn="true"] .tail {
  stroke: #f38b0c;
  transition-property: stroke;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-warn="true"] .head,
[data-layout="dendrogram"] svg .link[data-warn="true"] .head,
[data-layout="graph"] svg .link[data-warn="true"] .tail,
[data-layout="dendrogram"] svg .link[data-warn="true"] .tail {
  fill: #f38b0c;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-warn="true"] .desc,
[data-layout="dendrogram"] svg .link[data-warn="true"] .desc {
  fill: #f38b0c;
}
[data-layout="graph"] svg .link[data-warn="true"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-warn="true"]:hover .desc {
  fill: white;
}
[data-layout="graph"] svg .link[data-warn="true"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-warn="true"]:hover .desc-bg {
  fill: #de800d;
  opacity: 0.9;
}
[data-layout="graph"] svg .link[data-highlight="root"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .line,
[data-layout="graph"] svg .link[data-highlight="node"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .line {
  stroke-width: 4;
}
[data-layout="graph"] svg .link[data-highlight="root"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .desc,
[data-layout="graph"] svg .link[data-highlight="node"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .desc {
  opacity: 1;
  font-weight: bold;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"] .line,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"] .line,
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"] .head,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"] .head,
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"] .tail,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"] .tail {
  stroke: #7a7a7a;
  transition-property: stroke;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"] .head,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"] .head,
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"] .tail,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"] .tail {
  fill: #7a7a7a;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"] .desc,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"] .desc {
  fill: #7a7a7a;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"]:hover .desc,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"]:hover .desc {
  fill: white;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-old="true"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-old="true"]:hover .desc-bg,
[data-layout="graph"] svg .link[data-highlight="node"][data-old="true"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-old="true"]:hover .desc-bg {
  fill: #7a7a7a;
  opacity: 0.9;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"] .line,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"] .line,
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"] .head,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"] .head,
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"] .tail,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"] .tail {
  stroke: #de800d;
  transition-property: stroke;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"] .head,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"] .head,
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"] .tail,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"] .tail {
  fill: #de800d;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"] .desc,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"] .desc {
  fill: #de800d;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"]:hover .desc,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"]:hover .desc {
  fill: white;
}
[data-layout="graph"] svg .link[data-highlight="root"][data-warn="true"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="root"][data-warn="true"]:hover .desc-bg,
[data-layout="graph"] svg .link[data-highlight="node"][data-warn="true"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="node"][data-warn="true"]:hover .desc-bg {
  fill: #de800d;
  opacity: 0.9;
}
[data-layout="graph"] svg .link[data-highlight="root"] .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .desc-bg,
[data-layout="graph"] svg .link[data-highlight="node"] .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .desc-bg {
  opacity: 0.9;
}
[data-layout="graph"] svg .link:hover .line,
[data-layout="dendrogram"] svg .link:hover .line {
  stroke-width: 4;
}
[data-layout="graph"] svg .link:hover .desc,
[data-layout="dendrogram"] svg .link:hover .desc {
  opacity: 1;
  font-weight: bold;
}
[data-layout="graph"] svg .link[data-highlight="root"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .line,
[data-layout="graph"] svg .link[data-highlight="root"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .head,
[data-layout="graph"] svg .link[data-highlight="root"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .tail {
  stroke: #007388;
  transition-property: stroke;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="root"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .head,
[data-layout="graph"] svg .link[data-highlight="root"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .tail {
  fill: #007388;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="root"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="root"] .desc {
  fill: #007388;
}
[data-layout="graph"] svg .link[data-highlight="root"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-highlight="root"]:hover .desc {
  fill: white;
}
[data-layout="graph"] svg .link[data-highlight="root"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="root"]:hover .desc-bg {
  fill: #007388;
  opacity: 0.9;
}
[data-layout="graph"] svg .link[data-highlight="node"] .line,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .line,
[data-layout="graph"] svg .link[data-highlight="node"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .head,
[data-layout="graph"] svg .link[data-highlight="node"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .tail {
  stroke: #af4e4a;
  transition-property: stroke;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="node"] .head,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .head,
[data-layout="graph"] svg .link[data-highlight="node"] .tail,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .tail {
  fill: #af4e4a;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="graph"] svg .link[data-highlight="node"] .desc,
[data-layout="dendrogram"] svg .link[data-highlight="node"] .desc {
  fill: #af4e4a;
}
[data-layout="graph"] svg .link[data-highlight="node"]:hover .desc,
[data-layout="dendrogram"] svg .link[data-highlight="node"]:hover .desc {
  fill: white;
}
[data-layout="graph"] svg .link[data-highlight="node"]:hover .desc-bg,
[data-layout="dendrogram"] svg .link[data-highlight="node"]:hover .desc-bg {
  fill: #af4e4a;
  opacity: 0.9;
}
[data-layout="graph"] svg .link[data-helper],
[data-layout="dendrogram"] svg .link[data-helper] {
  display: none;
}
[data-layout="graph"] svg a.node,
[data-layout="dendrogram"] svg a.node {
  cursor: default;
}
[data-layout="graph"] svg a.node[*|href],
[data-layout="dendrogram"] svg a.node[*|href],
[data-layout="graph"] svg a[data-clickable="true"],
[data-layout="dendrogram"] svg a[data-clickable="true"] {
  cursor: pointer;
}
[data-layout="graph"] svg a.node[*|href] text,
[data-layout="dendrogram"] svg a.node[*|href] text,
[data-layout="graph"] svg a[data-clickable="true"] text,
[data-layout="dendrogram"] svg a[data-clickable="true"] text {
  cursor: pointer;
}
[data-layout="graph"] svg .node .text,
[data-layout="dendrogram"] svg .node .text,
[data-layout="graph"] svg .node .text-bg,
[data-layout="dendrogram"] svg .node .text-bg {
  transform: translateY(36px);
  font-size: 0.8rem;
  text-anchor: middle;
}
[data-layout="graph"] svg .node .icon,
[data-layout="dendrogram"] svg .node .icon {
  transform: translateY(7px);
  font-family: icons;
  font-size: 1.4rem;
  text-anchor: middle;
  fill: white;
}
[data-layout="graph"] svg .node circle,
[data-layout="dendrogram"] svg .node circle {
  fill: #a35956;
}
[data-layout="graph"] svg .node .text,
[data-layout="dendrogram"] svg .node .text {
  fill: #a35956;
}
[data-layout="graph"] svg .node .text-bg,
[data-layout="dendrogram"] svg .node .text-bg {
  fill: #a35956;
  opacity: 0;
}
[data-layout="graph"] svg .node circle,
[data-layout="dendrogram"] svg .node circle,
[data-layout="graph"] svg .node .text,
[data-layout="dendrogram"] svg .node .text,
[data-layout="graph"] svg .node .text-bg,
[data-layout="dendrogram"] svg .node .text-bg {
  transition-property: fill, opacity;
  transition-timing-function: ease-out;
  transition-duration: 0s;
}
[data-layout="graph"] svg .node:hover circle,
[data-layout="dendrogram"] svg .node:hover circle {
  fill: #af4e4a;
}
[data-layout="graph"] svg .node:hover .text,
[data-layout="dendrogram"] svg .node:hover .text {
  fill: white;
}
[data-layout="graph"] svg .node:hover .text-bg,
[data-layout="dendrogram"] svg .node:hover .text-bg {
  fill: #af4e4a;
  opacity: 1;
}
[data-layout="graph"] svg .node[data-old="true"] circle,
[data-layout="dendrogram"] svg .node[data-old="true"] circle {
  fill: #888888;
}
[data-layout="graph"] svg .node[data-old="true"] .text,
[data-layout="dendrogram"] svg .node[data-old="true"] .text {
  fill: #888888;
}
[data-layout="graph"] svg .node[data-old="true"] .text-bg,
[data-layout="dendrogram"] svg .node[data-old="true"] .text-bg {
  fill: #888888;
  opacity: 0;
}
[data-layout="graph"] svg .node[data-old="true"] circle,
[data-layout="dendrogram"] svg .node[data-old="true"] circle,
[data-layout="graph"] svg .node[data-old="true"] .text,
[data-layout="dendrogram"] svg .node[data-old="true"] .text,
[data-layout="graph"] svg .node[data-old="true"] .text-bg,
[data-layout="dendrogram"] svg .node[data-old="true"] .text-bg {
  transition-property: fill, opacity;
  transition-timing-function: ease-out;
  transition-duration: 0s;
}
[data-layout="graph"] svg .node[data-old="true"]:hover circle,
[data-layout="dendrogram"] svg .node[data-old="true"]:hover circle {
  fill: #7a7a7a;
}
[data-layout="graph"] svg .node[data-old="true"]:hover .text,
[data-layout="dendrogram"] svg .node[data-old="true"]:hover .text {
  fill: white;
}
[data-layout="graph"] svg .node[data-old="true"]:hover .text-bg,
[data-layout="dendrogram"] svg .node[data-old="true"]:hover .text-bg {
  fill: #7a7a7a;
  opacity: 1;
}
[data-layout="graph"] svg .node circle[data-warn],
[data-layout="dendrogram"] svg .node circle[data-warn] {
  fill: white !important;
}
[data-layout="graph"] svg .node circle[data-warn]:hover,
[data-layout="dendrogram"] svg .node circle[data-warn]:hover {
  fill: white !important;
}
[data-layout="graph"] svg .node text[data-warn],
[data-layout="dendrogram"] svg .node text[data-warn] {
  transform: translate(20px, -15px);
  fill: #f38b0c;
  stroke: lightgray;
  stroke-width: 1;
}
[data-layout="graph"] svg .node text[data-warn]:hover,
[data-layout="dendrogram"] svg .node text[data-warn]:hover {
  fill: #de800d;
}
[data-layout="graph"] svg .node[data-root="true"] .text,
[data-layout="dendrogram"] svg .node[data-root="true"] .text {
  font-weight: bold;
}
[data-layout="graph"] svg .node[data-root="true"] circle,
[data-layout="dendrogram"] svg .node[data-root="true"] circle {
  fill: #007388;
}
[data-layout="graph"] svg .node[data-root="true"] .text,
[data-layout="dendrogram"] svg .node[data-root="true"] .text {
  fill: #007388;
}
[data-layout="graph"] svg .node[data-root="true"] .text-bg,
[data-layout="dendrogram"] svg .node[data-root="true"] .text-bg {
  fill: #007388;
  opacity: 0;
}
[data-layout="graph"] svg .node[data-root="true"] circle,
[data-layout="dendrogram"] svg .node[data-root="true"] circle,
[data-layout="graph"] svg .node[data-root="true"] .text,
[data-layout="dendrogram"] svg .node[data-root="true"] .text,
[data-layout="graph"] svg .node[data-root="true"] .text-bg,
[data-layout="dendrogram"] svg .node[data-root="true"] .text-bg {
  transition-property: fill, opacity;
  transition-timing-function: ease-out;
  transition-duration: 0s;
}
[data-layout="graph"] svg .node[data-root="true"]:hover circle,
[data-layout="dendrogram"] svg .node[data-root="true"]:hover circle {
  fill: #007388;
}
[data-layout="graph"] svg .node[data-root="true"]:hover .text,
[data-layout="dendrogram"] svg .node[data-root="true"]:hover .text {
  fill: white;
}
[data-layout="graph"] svg .node[data-root="true"]:hover .text-bg,
[data-layout="dendrogram"] svg .node[data-root="true"]:hover .text-bg {
  fill: #007388;
  opacity: 1;
}
[data-layout="graph"] svg[data-dense="true"] .link,
[data-layout="dendrogram"] svg[data-dense="true"] .link {
  stroke-width: 1;
}
[data-layout="graph"] svg[data-dense="true"] .node[data-leaf="true"] circle,
[data-layout="dendrogram"] svg[data-dense="true"] .node[data-leaf="true"] circle {
  display: none;
}
[data-layout="graph"] svg[data-dense="true"] .node[data-leaf="true"] .text,
[data-layout="dendrogram"] svg[data-dense="true"] .node[data-leaf="true"] .text,
[data-layout="graph"] svg[data-dense="true"] .node[data-leaf="true"] .text-bg,
[data-layout="dendrogram"] svg[data-dense="true"] .node[data-leaf="true"] .text-bg {
  transform: translateY(3px) translateX(25px);
  text-anchor: start;
}
[data-layout="graph"] svg[data-dense="true"] .node[data-leaf="true"] .icon,
[data-layout="dendrogram"] svg[data-dense="true"] .node[data-leaf="true"] .icon {
  transform: translateY(4px) translateX(6px);
  font-size: 1rem;
  text-anchor: start;
  fill: #a35956;
}
[data-layout="graph"] svg[data-dense="true"] .node[data-leaf="true"][data-old="true"] .icon,
[data-layout="dendrogram"] svg[data-dense="true"] .node[data-leaf="true"][data-old="true"] .icon {
  fill: #888888;
}
[data-layout="graph"] svg[data-dense="true"] .node[data-leaf="true"][data-warn="true"] .icon,
[data-layout="dendrogram"] svg[data-dense="true"] .node[data-leaf="true"][data-warn="true"] .icon {
  fill: #f38b0c;
}
[data-layout="pubTable"],
[data-layout="searchResults"] {
  padding: 1em;
}
[data-layout="pubTable"] h2,
[data-layout="searchResults"] h2 {
  font-size: 115%;
  margin-top: 0;
  margin-bottom: 0px;
}
[data-layout="pubTable"] h2 a,
[data-layout="searchResults"] h2 a {
  text-decoration: none;
}
[data-layout="pubTable"] ul.search-results,
[data-layout="searchResults"] ul.search-results {
  list-style-type: none;
  padding-left: 0;
  margin: 0 0 0.5em;
}
[data-layout="pubTable"] ul.search-results li.search-result,
[data-layout="searchResults"] ul.search-results li.search-result {
  padding-left: 0;
}
[data-layout="pubTable"] ul.search-results li.search-result .icon,
[data-layout="searchResults"] ul.search-results li.search-result .icon {
  display: block;
  float: left;
  font-size: 150%;
  margin-top: 0.1em;
  margin-right: 1ex;
}
[data-layout="pubTable"] .more,
[data-layout="searchResults"] .more {
  margin-top: 1em;
}
[data-layout="pubTable"] .more a:not(:empty):after,
[data-layout="searchResults"] .more a:not(:empty):after {
  margin-left: 0.15em;
  content: "►";
}
[data-layout="pubTable"] .icon,
[data-layout="searchResults"] .icon {
  font-family: icons;
  font-weight: normal;
}
[data-layout="pubTable"] .pub,
[data-layout="searchResults"] .pub,
[data-layout="pubTable"] .search-result,
[data-layout="searchResults"] .search-result {
  padding: 0.5em;
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
[data-layout="pubTable"] .pub .icon,
[data-layout="searchResults"] .pub .icon {
  width: 1.2em;
  position: relative;
  text-align: center;
  display: inline-block;
}
[data-layout="pubTable"] .pub .date,
[data-layout="searchResults"] .pub .date {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
[data-layout="pubTable"] .pub .text,
[data-layout="searchResults"] .pub .text {
  margin-top: 0.5em;
}
[data-layout="pubTable"] .pub .text .lead,
[data-layout="searchResults"] .pub .text .lead {
  text-decoration: underline;
  font-weight: bold;
}
[data-layout="pubTable"] .pub .text .company,
[data-layout="searchResults"] .pub .text .company {
  font-weight: bold;
}
[data-layout="pubTable"] .pub .text .person,
[data-layout="searchResults"] .pub .text .person {
  font-weight: bold;
}
[data-layout="pubTable"] .pub .text address,
[data-layout="searchResults"] .pub .text address {
  display: inline;
}
[data-layout="pubTable"] .pub h2 a:after,
[data-layout="searchResults"] .pub h2 a:after {
  margin-left: 0.15em;
  font-size: 75%;
  content: "►";
}
[data-layout="pubTable"] .pub .text,
[data-layout="searchResults"] .pub .text {
  display: none;
}
[data-layout="pubTable"] .pub.expanded h2 a:after,
[data-layout="searchResults"] .pub.expanded h2 a:after {
  content: "▼";
}
[data-layout="pubTable"] .pub.expanded .text,
[data-layout="searchResults"] .pub.expanded .text {
  display: inherit;
}
.snippets .snippet h4 {
  margin: 0;
}
.snippets .snippet table td,
.snippets .snippet table th {
  padding: 0 0.5em 0 0;
}
.snippets .snippet table td p,
.snippets .snippet table th p {
  padding: 0;
  margin: 0;
}
[data-layout="sheet"] .ui.bizq.table tbody tr td:not(:first-child) {
  text-align: right;
}
[data-layout="sheet"] .ui.bizq.table tbody tr td:first-child {
  min-width: 20em;
}
@media (max-width: 767.98px) {
  [data-layout="sheet"] .ui.bizq.table tbody tr td:first-child {
    min-width: 12em;
  }
}
[data-layout="financials"] .ui.bizq.table tbody tr td:first-child {
  min-width: 12em;
}
[data-layout="financials"] thead th:first-child {
  text-transform: uppercase;
}
[data-layout="sheet"] a[download],
[data-layout="financials"] a[download] {
  float: right;
}
[data-layout="sheet"] a[download] .icon.download,
[data-layout="financials"] a[download] .icon.download {
  font-family: icons;
  font-size: 1.3em;
}
[data-layout="sheet"] a[download] .icon.download:before,
[data-layout="financials"] a[download] .icon.download:before {
  content: "\f019";
  font-style: normal;
  font-weight: normal;
}
[data-layout="sheet"] .note,
[data-layout="financials"] .note {
  display: block;
  white-space: normal !important;
}
[data-layout="sheet"] .note:before,
[data-layout="financials"] .note:before {
  content: "(";
}
[data-layout="sheet"] .note:after,
[data-layout="financials"] .note:after {
  content: ")";
}
[data-layout="sheet"] .ui.bizq.table .hidden,
[data-layout="financials"] .ui.bizq.table .hidden {
  display: none;
}
[data-layout="sheet"] .ui.bizq.table thead,
[data-layout="financials"] .ui.bizq.table thead,
[data-layout="sheet"] .ui.bizq.table tfoot,
[data-layout="financials"] .ui.bizq.table tfoot {
  text-align: center;
}
[data-layout="sheet"] .ui.bizq.table thead th:first-child,
[data-layout="financials"] .ui.bizq.table thead th:first-child,
[data-layout="sheet"] .ui.bizq.table tfoot th:first-child,
[data-layout="financials"] .ui.bizq.table tfoot th:first-child {
  text-align: left;
}
[data-layout="sheet"] .ui.bizq.table tfoot th:not(:first-child),
[data-layout="financials"] .ui.bizq.table tfoot th:not(:first-child) {
  font-size: 95%;
}
[data-layout="sheet"] .ui.bizq.table tbody .expanded,
[data-layout="financials"] .ui.bizq.table tbody .expanded,
[data-layout="sheet"] .ui.bizq.table tbody .closed,
[data-layout="financials"] .ui.bizq.table tbody .closed {
  cursor: default;
}
[data-layout="sheet"] .ui.bizq.table tbody .expanded[data-child-count],
[data-layout="financials"] .ui.bizq.table tbody .expanded[data-child-count],
[data-layout="sheet"] .ui.bizq.table tbody .closed[data-child-count],
[data-layout="financials"] .ui.bizq.table tbody .closed[data-child-count] {
  cursor: pointer;
}
[data-layout="sheet"] .ui.bizq.table tbody .expanded td:first-child:after,
[data-layout="financials"] .ui.bizq.table tbody .expanded td:first-child:after,
[data-layout="sheet"] .ui.bizq.table tbody .closed td:first-child:after,
[data-layout="financials"] .ui.bizq.table tbody .closed td:first-child:after {
  font-size: 75%;
  margin-left: 0.25em;
}
[data-layout="sheet"] .ui.bizq.table tbody .closed[data-child-count] td:first-child:after,
[data-layout="financials"] .ui.bizq.table tbody .closed[data-child-count] td:first-child:after {
  content: "►";
}
[data-layout="sheet"] .ui.bizq.table tbody .expanded[data-child-count] td:first-child:after,
[data-layout="financials"] .ui.bizq.table tbody .expanded[data-child-count] td:first-child:after {
  content: "▼";
}
[data-layout="sheet"] .ui.bizq.table tbody tr,
[data-layout="financials"] .ui.bizq.table tbody tr {
  cursor: default;
}
[data-layout="sheet"] .ui.bizq.table tbody tr td,
[data-layout="financials"] .ui.bizq.table tbody tr td {
  white-space: nowrap;
}
[data-layout="sheet"] .ui.bizq.table tbody tr td .auditor,
[data-layout="financials"] .ui.bizq.table tbody tr td .auditor {
  white-space: normal;
}
[data-layout="sheet"] .ui.bizq.table tbody tr td:first-child,
[data-layout="financials"] .ui.bizq.table tbody tr td:first-child {
  white-space: normal;
  text-align: left;
}
[data-layout="sheet"] .ui.bizq.table tbody tr[data-level="0"] td:first-child,
[data-layout="financials"] .ui.bizq.table tbody tr[data-level="0"] td:first-child {
  font-weight: bold;
}
[data-layout="sheet"] .ui.bizq.table tbody tr[data-level="2"] td:first-child,
[data-layout="financials"] .ui.bizq.table tbody tr[data-level="2"] td:first-child {
  padding-left: 1.5em;
}
[data-layout="sheet"] .ui.bizq.table tbody tr[data-level="3"] td:first-child,
[data-layout="financials"] .ui.bizq.table tbody tr[data-level="3"] td:first-child {
  padding-left: 3em;
}
[data-layout="sheet"] .ui.bizq.table tbody tr[data-level="4"] td:first-child,
[data-layout="financials"] .ui.bizq.table tbody tr[data-level="4"] td:first-child {
  padding-left: 4.5em;
}
[data-layout="sheet"] .ui.bizq.table tbody tr[data-level="5"] td:first-child,
[data-layout="financials"] .ui.bizq.table tbody tr[data-level="5"] td:first-child {
  padding-left: 6em;
}
[data-layout="sheet"] .ui.bizq.table tbody tr[data-summary="true"] td,
[data-layout="financials"] .ui.bizq.table tbody tr[data-summary="true"] td {
  border-top-width: 3px;
}
[data-layout="sheet"] .ui.bizq.table tbody tr[data-summary="true"] td:first-child,
[data-layout="financials"] .ui.bizq.table tbody tr[data-summary="true"] td:first-child {
  font-weight: bold;
}
/*
 *  Web site style sheet dependencies
 */
/*******************************
        Import Directives
*******************************/
/* --------------------
   Site Variables
--------------------- */
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/***********************************************************
  Central element variation compilation enablers
***********************************************************/
/* General */
/*******************************
           Elements
*******************************/
/* Button */
/* Container */
/* Divider */
/* Header */
/* Icon */
/* Font Awesome Pro only */
/* Font Awesome Pro only */
/* Image */
/* Input */
/* Label */
/* List */
/* Loader */
/* Placeholder */
/* Rail */
/* Reveal */
/* Segment */
/* Step */
/* Text */
/*******************************
           Collections
*******************************/
/* Breadcrumb */
/* Form */
/* Grid */
/* Menu */
/* Message */
/* Table */
/*******************************
             Views
*******************************/
/* Ad */
/* Card */
/* Comment */
/* Feed */
/* Item */
/* Statistic */
/*******************************
            Modules
*******************************/
/* Accordion */
/* Calendar */
/* Checkbox */
/* Dimmer */
/* Dropdown */
/* Embed */
/* Flyout */
/* Modal */
/* Nag */
/* Popup */
/* Progress */
/* Rating */
/* Search */
/* Shape */
/* Sidebar */
/* Slider */
/* Tab */
/* Toast */
/* Transition */
/* Emojis */
/* -------------------
       Fonts
-------------------- */
/* -------------------
      Base Sizes
-------------------- */
/* This is the single variable that controls them all */
/* The size of page text */
/* -------------------
    Border Radius
-------------------- */
/* See Power-user section below
   for explanation of @px variables
*/
/* -------------------
    Brand Colors
-------------------- */
/* Whenever a color needs to get calculated (screen()/multiply()) out of a base color */
/* --------------
  Page Heading
--------------- */
/* --------------
   Form Input
--------------- */
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/* -------------------
    Focused Input
-------------------- */
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/* -------------------
        Sizes
-------------------- */
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/* -------------------
        Page
-------------------- */
/* -------------------
      Paragraph
-------------------- */
/* -------------------
       Links
-------------------- */
/* -------------------
    Scroll Bars
-------------------- */
/* Inverted */
/* IE needs hex values */
/* Scrolling */
/* -------------------
  Highlighted Text
-------------------- */
/* -------------------
       Loader
-------------------- */
/* -------------------
        Grid
-------------------- */
/* -------------------
     Transitions
-------------------- */
/* -------------------
     Breakpoints
-------------------- */
/* -------------------
      Site Colors
-------------------- */
/* ---  Colors  --- */
/* ---  Light Colors  --- */
/* ---   Neutrals  --- */
/* --- Colored Backgrounds --- */
/* --- Colored Headers --- */
/* --- Colored Text --- */
/* --- Light Colored Text --- */
/* --- Hovered Colored Text --- */
/* --- Colored Border --- */
/* --- Shadows --- */
/* Inverted */
/* Box Shadows */
/* -------------------
     Alpha Colors
-------------------- */
/* -------------------
       Accents
-------------------- */
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/* -------------------
    Emotive Colors
-------------------- */
/* Positive */
/* Negative */
/* Info */
/* Warning */
/* -------------------
        Paths
-------------------- */
/* For source only. Modified in gulp for dist */
/* -------------------
       Em Sizes
-------------------- */
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/* -------------------
       Icons
-------------------- */
/* Maximum Glyph Width of Icon */
/* -------------------
     Neutral Text
-------------------- */
/* -------------------
     Brand Colors
-------------------- */
/* -------------------
      Borders
-------------------- */
/* -------------------
    Derived Values
-------------------- */
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/* -------------------
  Exact Pixel Values
-------------------- */
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/* -------------------
      Disabled
-------------------- */
/* -------------------
        Hover
-------------------- */
/* ---  Shadows  --- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Focus
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
    Down (:active)
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* -------------------
        Active
-------------------- */
/* ---  Colors  --- */
/* ---  Emotive  --- */
/* ---  Brand   --- */
/* ---  Dark Tones  --- */
/* ---  Light Tones  --- */
/* ---  Tertiary  --- */
/* ---  Bright  --- */
/*******************************
 States shared in Form-related components
 *******************************/
/* Form state */
/* Input state */
/* AutoFill */
/* Dropdown state */
/* Focused state */
/* Placeholder state */
/* Site theme site.variables */
/*******************************
     User Global Variables
*******************************/
/***********************************************************
  Central element variation compilation enablers
***********************************************************/
/*******************************
           Elements
*******************************/
/* Button */
/* Container */
/* Divider */
/* Header */
/* Icon */
/* Font Awesome Pro only */
/* Font Awesome Pro only */
/* Image */
/* Input */
/* Label */
/* List */
/* Loader */
/* Placeholder */
/* Rail */
/* Reveal */
/* Segment */
/* Step */
/* Text */
/*******************************
           Collections
*******************************/
/* Breadcrumb */
/* Form */
/* Grid */
/* Menu */
/* Message */
/* Table */
/*******************************
             Views
*******************************/
/* Ad */
/* Card */
/* Comment */
/* Feed */
/* Item */
/* Statistic */
/*******************************
            Modules
*******************************/
/* Accordion */
/* Calendar */
/* Checkbox */
/* Dimmer */
/* Dropdown */
/* Embed */
/* Flyout */
/* Modal */
/* Nag */
/* Popup */
/* Progress */
/* Rating */
/* Search */
/* Shape */
/* Sidebar */
/* Slider */
/* Tab */
/* Toast */
/* Transition */
/* Emojis */
/* -------------------------
    Central Color Map
------------------------- */
/* Default */
/***********************************************************
  Central Color Mapping Base for all components to iterate
***********************************************************/
/***********************************************************
  Color Mapping Base for form components to iterate
***********************************************************/
/*
 *  Web site styles (except visualizations) 
 */
.unbranded header,
.unbranded .brand-only,
.unbranded .ad-container,
.unbranded .admin,
.unbranded .primary.segment h2 {
  display: none !important;
}
@page {
  size: A4;
  margin-left: 25mm;
  margin-right: 15mm;
  margin-top: 20mm;
  margin-bottom: 20mm;
  @bottom-left {
    margin: 10pt 0 30pt 0;
    border-top: 0.25pt solid #666;
    content: "date" string(date);
    font-size: 9pt;
    color: #333;
  }
  @bottom-right {
    content: "Seite " counter(page) " von " counter(pages);
  }
}
.small {
  font-size: 90%;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: #f5f5f5;
  font-weight: 300;
}
body.waiting,
body.waiting * {
  cursor: wait !important;
}
body a,
body a:hover,
body a:active,
body button,
body button:hover,
body button:active,
body [data-clickable="true"],
body a *,
body a:hover *,
body a:active *,
body button *,
body button:hover *,
body button:active *,
body [data-clickable="true"] * {
  font-weight: 500 !important;
}
body a sup,
body a:hover sup,
body a:active sup,
body button sup,
body button:hover sup,
body button:active sup,
body [data-clickable="true"] sup {
  font-weight: 600 !important;
}
body a:not(.button) .icon {
  font-size: 115%;
}
body .tooltip {
  font-size: 100% / 1.07;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
}
body .tooltip .source {
  display: block;
}
body i.icon {
  cursor: default;
  font-weight: normal !important;
}
body option {
  font-family: inherit;
  font-weight: inherit;
}
body button i.icon,
body a i.icon {
  cursor: pointer;
}
body [data-show-for]:not([data-show="true"]) {
  display: none;
}
body .ui.input,
body .ui.menu,
body .ui.pointing.label {
  font-weight: 300 !important;
}
body .ui.input a,
body .ui.menu a,
body .ui.pointing.label a,
body .ui.input a:hover,
body .ui.menu a:hover,
body .ui.pointing.label a:hover,
body .ui.input a:active,
body .ui.menu a:active,
body .ui.pointing.label a:active,
body .ui.input a *,
body .ui.menu a *,
body .ui.pointing.label a *,
body .ui.input a:hover *,
body .ui.menu a:hover *,
body .ui.pointing.label a:hover *,
body .ui.input a:active *,
body .ui.menu a:active *,
body .ui.pointing.label a:active * {
  font-weight: 300 !important;
}
body .ui.menu button.item {
  font-weight: 300 !important;
  cursor: pointer;
}
body .ui.icon.message {
  align-items: flex-start;
}
body .ui.secondary.bottom.pointing.menu .item {
  border-top-color: transparent;
  border-top-width: 2px;
  border-top-style: solid;
  border-bottom-style: none;
}
body .ui.top.attached.pointing.secondary.menu {
  border-top: none !important;
}
body .ui.form .field > label,
body th,
body .ui.horizontal.divider,
body .ui.definition.table tr td:first-child {
  font-weight: 500 !important;
}
body .divider.large {
  margin: 25px 0;
}
body .ui.horizontal.divider::after,
body .ui.horizontal.divider::before {
  top: 0 !important;
}
body > header {
  background: white;
}
body > header nav .logo:before,
body > header nav .header-menu-container:before,
body > header nav .logo:after,
body > header nav .header-menu-container:after {
  display: none;
}
body > header nav .logo:hover,
body > header nav .header-menu-container:hover {
  background: transparent !important;
}
body > header nav .logo {
  padding: 2rem 0 !important;
}
body > header nav .logo .image {
  margin: 0 !important;
  height: 77px;
  width: 234px !important;
  display: block;
  background-image: url("/ui/v1-244-10/img/headerlogo.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  body > header nav .logo .image {
    background-image: url("/ui/v1-244-10/img/headerlogo@2x.png");
    background-size: 470px 154px;
    background-size: contain;
  }
}
body > header nav .header-menu > .item,
body > header nav.ui.menu .ui.dropdown .menu > .active.item,
body > header nav .dropdown .menu > .item {
  font-weight: 300 !important;
  color: inherit !important;
}
body > header nav .header-menu-container.right {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
body > header nav .header-menu-container .ui.dropdown .menu > .header,
body > header nav .header-menu-container .ui.dropdown .menu > .item {
  text-transform: none !important;
  font-weight: 300 !important;
  font-size: 90% !important;
}
body > header nav .phone.dropdown {
  /*
				margin:0 !important;
				 
				.ui.secondary.menu {
					left: auto;
					right: 0;
					> .item {
						font-size: 1.2rem !important;
					}
				}
			*/
}
body > header nav .phone.dropdown .content.icon {
  padding: 1rem 1.5rem !important;
  margin: 0 auto !important;
  font-size: 3.5rem;
  width: auto;
  height: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  /*		&:hover {
						background: @nd-white;
					}*/
}
body > header nav,
body > header nav .no-phone .menu {
  border: none !important;
  box-shadow: none !important;
}
body footer {
  margin-bottom: 80px;
}
body footer > nav {
  justify-content: center;
}
body footer > nav .item {
  justify-content: center;
}
@media (max-width: 767.98px) {
  body footer {
    margin-bottom: 160px;
  }
}
@media (max-width: 1199.98px) {
  body .header-menu > .item {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}
@media (max-width: 767.98px) {
  body .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  body .ui.container .content > .ui.primary.segment {
    margin: 0 !important;
  }
  body .ui.container .content > .ui.segments {
    margin-top: 0 !important;
  }
  body main.ui.container {
    margin-top: 0 !important;
  }
  body .header.logo {
    padding-left: 1.5rem !important;
  }
  body .board {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  body .content > .ui.message {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
  }
}
body .no-phone {
  display: none !important;
}
@media (min-width: 992px) {
  body .phone {
    display: none !important;
  }
  body .no-phone {
    display: inherit !important;
  }
}
body .display-none.display-none.display-none.display-none.display-none {
  display: none !important;
}
body .ui.primary.segment {
  color: white;
  background-color: #007388;
  padding: 2rem !important;
}
@media (min-width: 768px) {
  body .ui.primary.segment {
    padding: 3rem !important;
  }
}
body h1.ui.header {
  overflow-x: hidden;
  margin-top: -3px !important;
  padding-top: 3px;
}
body h2.ui.header.claim {
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", serif;
  font-style: normal;
  font-size: 1.28em;
  color: #f5f5f5;
}
body .ui.dropdown .menu > .divider + .country-group-item {
  border-top: unset;
}
body .ui.dropdown .item > span,
body .ui.dropdown a > span {
  vertical-align: top;
}
body .ui.dropdown .country-item {
  display: inline-flex;
  align-items: center;
}
body .ui.dropdown .menu > .message {
  font-weight: 300;
}
body #search {
  width: 100%;
  box-sizing: border-box !important;
}
body #search .search.icon {
  top: -1px;
}
body #search .line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
body #search .line.last {
  margin-bottom: 0;
}
body #search .line > * {
  margin: 0 15px 0 0 !important;
}
body #search .line > *:last-child {
  margin-right: 0px !important;
}
body #search .input {
  width: 100%;
}
body #search .input input {
  border-radius: 0 !important;
  font-weight: 300 !important;
}
body #search .input i.icon {
  cursor: pointer;
}
body #search i.info.icon {
  color: rgba(0, 0, 0, 0.4);
}
body #search .power-search .field label {
  height: 20.4px;
}
body #search .power-search .tips-and-tricks {
  align-self: center;
  line-height: 1em;
  font-size: 90%;
  opacity: 0.6;
  margin-bottom: 14px !important;
}
body #search .power-search .tips-and-tricks i.icon {
  opacity: 0.8;
  font-size: 110%;
}
body #search .power-search .tips-and-tricks:hover {
  opacity: 0.7;
}
body #search .universal-search .tips-and-tricks {
  margin-left: 0;
  margin-right: auto;
  align-self: center;
  font-size: 90%;
  color: white;
  opacity: 0.8;
  font-weight: 300 !important;
}
body #search .universal-search .tips-and-tricks i.icon {
  opacity: 0.5;
  font-size: 110%;
}
body #search .universal-search .tips-and-tricks:hover {
  opacity: 0.9;
}
body #search .universal-search .tips-and-tricks:hover i.icon {
  opacity: 0.55;
}
body #search .universal-search .ui.search .label {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  border-color: transparent !important;
  color: inherit !important;
}
body #search .universal-search .ui.search .label .link {
  transition: opacity 0.3s ease, background 0.3s ease !important;
  color: inherit !important;
  opacity: 0.5;
}
body #search .universal-search .ui.search .label .link:not(.visible) {
  opacity: 0 !important;
  cursor: default;
}
body #search .universal-search .ui.search .label .link:hover {
  opacity: 1;
  color: inherit !important;
}
body #search .universal-search .country-selection {
  margin-top: 14px;
  margin-bottom: calc(14px - 28px);
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  body #search .universal-search .country-selection {
    margin-bottom: calc(14px - 42px);
  }
}
body #search .universal-search .country-selection .ui.dropdown {
  min-height: 30px;
  padding: 0;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
}
body #search .universal-search .country-selection .ui.dropdown i.icon.dropdown {
  order: -1;
  opacity: 0.5;
}
body #search .universal-search .country-selection .ui.dropdown .country-item span {
  font-weight: 300 !important;
}
body #search .universal-search .country-selection .ui.dropdown:hover .default.text {
  opacity: 0.9;
}
body #search .universal-search .country-selection .ui.dropdown:hover i.icon.dropdown {
  opacity: 0.8;
}
body #search .universal-search .country-selection .ui.label {
  box-shadow: none;
  border-radius: 0;
  background-color: rgba(163, 179, 192, 0.4);
  color: white;
  cursor: default;
}
body #search .universal-search .country-selection .default.text {
  line-height: 1;
  font-weight: 300;
  margin: 0 5px 0 0;
  color: white;
  opacity: 0.8;
}
body #search .universal-search .country-selection .default.text > div {
  display: flex;
  align-items: center;
}
body #search .universal-search .country-selection .default.text .info.icon {
  margin-right: 0.5rem;
  box-shadow: 0 0 0 0.08em #a3b3c0 inset;
  font-size: 0.65em;
  color: white;
}
body #search .universal-search .country-selection i.dropdown.icon,
body #search .universal-search .country-selection i.delete.icon {
  cursor: pointer;
}
body #search .power-search .ui.dropdown .item > span {
  padding-left: 0.5em;
  margin-left: 16px;
  line-height: 1.2em;
  display: inline-block;
}
body #search .power-search .ui.multiple.dropdown:not(.search) .default.text {
  margin-top: 5px;
  margin-bottom: 5px;
}
body #search .ui.scrolling.dropdown .menu {
  min-width: calc(100% + 2px) !important;
}
body #search .universal-search .ui.search .results {
  width: 100%;
  max-width: 700px;
}
body #search .ui.search > .results > .message {
  display: none;
}
body #search .ui.category.search input {
  border-color: #fafafa !important;
}
body #search .ui.search > .results:empty {
  visibility: hidden !important;
}
body #search .ui.search .category > .name {
  font-family: "Josefin Sans", serif !important;
  font-weight: 700;
  text-transform: uppercase !important;
}
body #search .ui.search .result .title {
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
body #search i.flag {
  opacity: 0.75;
}
body #search .ui.label {
  font-weight: 500;
}
body #search .ui.label.dropdown > i.dropdown.icon {
  margin: 0 0 0 0.25em;
}
body #search .min-to-max-dash {
  font-size: 20px;
}
body #search .segment-code.dropdown {
  flex: 1;
  min-width: 0;
}
body #search .segment-code.dropdown .label {
  font-weight: 500 !important;
}
body #search .segment-code.dropdown > .menu > .item {
  display: table-row;
}
body #search .segment-code.dropdown > .menu > .item > * {
  display: table-cell;
  padding: 10px 16px;
}
body #search .segment-code.dropdown > .menu > .item > * + * {
  padding-left: 0;
  width: 100%;
  line-height: 18px;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
body #search .segment-code.dropdown > .menu > .item > .code {
  margin-right: 0.5em;
  vertical-align: middle;
}
body #search .segment-code.dropdown > .menu > .item > .code .label {
  border-radius: 500rem;
  margin: -0.5em -0.75em;
  padding-left: 0.75em !important;
  padding-right: 0.75em !important;
}
body #search .search-filter .left.menu .item {
  text-align: right;
}
body #search .search-filter i.link.icon {
  margin-right: 0.5em;
}
body #search .search-filter i.link.icon:focus {
  opacity: 1;
  outline: none;
}
body #search .search-filter + .search-filter > label {
  display: none;
}
body #search .form-actions {
  margin-top: 20px;
  margin-bottom: -1em;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
body #search .form-actions > * {
  margin-bottom: 1em !important;
  margin-right: 0.5em !important;
}
body #search .form-actions button[type="submit"] {
  margin-left: auto;
  margin-right: 0 !important;
}
i.flag.ch {
  width: 11px;
  margin-left: 2.5px;
  margin-right: calc(2.5px + 0.5em);
}
i.flag.hvn::before {
  height: 12px !important;
  background-position: 0 0 !important;
  background: url("/ui/v1-244-10/img/icons/dollar.png") no-repeat !important;
}
i.flag.ru {
  display: none !important;
}
i.flag.gg::before {
  background: url("/ui/v1-244-10/img/icons/gg.png") no-repeat !important;
}
i.flag.je::before {
  background: url("/ui/v1-244-10/img/icons/je.png") no-repeat !important;
}
body p.strong,
body span.strong,
body strong {
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 500;
}
body p.strong a,
body span.strong a,
body strong a {
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 500;
}
body h1.ui.header.qualified,
body h2.ui.header.qualified,
body h3.ui.header.qualified,
body h4.ui.header.qualified {
  display: flex;
  align-items: center;
}
body h1.ui.header.with-buttons,
body h2.ui.header.with-buttons,
body h3.ui.header.with-buttons,
body h4.ui.header.with-buttons {
  flex-wrap: wrap;
}
body h1.ui.header.with-buttons > .content,
body h2.ui.header.with-buttons > .content,
body h3.ui.header.with-buttons > .content,
body h4.ui.header.with-buttons > .content {
  flex-shrink: 0;
}
body h1.ui.header.with-buttons > .buttons,
body h2.ui.header.with-buttons > .buttons,
body h3.ui.header.with-buttons > .buttons,
body h4.ui.header.with-buttons > .buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 1rem;
}
body h1.ui.header.with-buttons > .buttons .button,
body h2.ui.header.with-buttons > .buttons .button,
body h3.ui.header.with-buttons > .buttons .button,
body h4.ui.header.with-buttons > .buttons .button {
  margin: 0;
}
body h1.ui.header:first-child,
body h2.ui.header:first-child,
body h3.ui.header:first-child,
body h4.ui.header:first-child {
  margin-top: 0px !important;
}
body h1.ui.header > .content,
body h2.ui.header > .content,
body h3.ui.header > .content,
body h4.ui.header > .content {
  flex-grow: 100;
}
body h1.ui.header > .icon,
body h2.ui.header > .icon,
body h3.ui.header > .icon,
body h4.ui.header > .icon {
  padding-top: 0;
  font-size: 1.4em !important;
  vertical-align: top;
}
body h1.ui.header > .icon > i.icon,
body h2.ui.header > .icon > i.icon,
body h3.ui.header > .icon > i.icon,
body h4.ui.header > .icon > i.icon {
  margin-right: 0;
}
body h1.ui.header .sub.header,
body h2.ui.header .sub.header,
body h3.ui.header .sub.header,
body h4.ui.header .sub.header {
  font-size: 1.1rem;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 300;
  padding: 0em;
  margin-bottom: 0.28em;
  line-height: 1.15em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none !important;
}
body h1.ui.header,
body h2.ui.header {
  line-height: 1.15em;
  text-transform: uppercase;
}
body h1.ui.header .content .heading,
body h2.ui.header .content .heading {
  display: block;
}
body h3.ui.header {
  line-height: 1.15em;
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 300 !important;
}
body h4 {
  line-height: 1.15em;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 500 !important;
}
body .ui.message .header {
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 300 !important;
}
body .ui.segments:not(.horizontal) > .segment:last-child {
  padding-bottom: 2rem;
}
@media (max-width: 767.98px) {
  body .ui.segment .ui.ribbon.label {
    min-width: 0;
  }
}
@media (min-width: 992px) {
  body[data-ads="false"] .ui.ribbon.large.label {
    font-size: 1.1rem;
    margin-bottom: 1rem !important;
    margin-top: 0.5rem;
  }
  body[data-ads="false"] .ui.ribbon.large.label:after {
    border-width: 0 1em 1em 0;
  }
  body[data-ads="false"] .ui.segment {
    padding: 1rem 3rem;
  }
  body[data-ads="false"] .ui.segment .ui.ribbon.label {
    left: calc(-3rem - 1.2em);
    margin-right: -1.2em;
    padding-left: calc(3rem + 1.2em);
    padding-right: 1.2em;
  }
  body[data-ads="false"] .ui.info.segment {
    padding: 2rem 3rem;
  }
  body[data-ads="false"] .ui.info.segment .ui.ribbon.large.label {
    margin-top: 1rem;
  }
}
body .ui.single.segment {
  min-height: 420px;
}
body .ui.segment li {
  margin-bottom: 1em;
}
body .ui.segment {
  clear: both;
}
body .large-font .ui.segment {
  font-size: 1.1rem;
}
body .intro h1.ui.header {
  color: #a3b3c0 !important;
  text-transform: uppercase !important;
  font-size: 3rem !important;
  line-height: 1 !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  display: block;
}
@media (min-width: 768px) {
  body .intro h1.ui.header {
    font-size: 4rem !important;
  }
  body .ui.ribbon.label.float-left {
    float: left;
  }
}
body .samples h3.ui.header {
  text-transform: uppercase;
  margin-top: 1.2em !important;
}
body .samples p {
  line-height: 1.5;
  margin-bottom: 0.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
body .samples p .label {
  margin: 0.25rem;
}
body .extended-heading {
  margin: -0.5rem -1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
body .extended-heading > * {
  margin: 0.5rem 1rem !important;
}
body .extended-heading h1.ui.header {
  padding-top: 0.5rem !important;
  overflow-x: hidden;
}
@media (max-width: 767.98px) {
  body .extended-heading h1.ui.header .content .heading {
    font-size: 1.28rem;
    line-height: 1.15em;
  }
}
body .extended-heading .buttons {
  flex-grow: 0;
  display: flex;
}
body .extended-heading .button:not(:first-child) {
  margin-left: 0.5rem;
}
body .extended-heading .button {
  min-width: 120px;
  max-width: 180px;
}
body .ui.modal.document-shop tr {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
body .ui.modal.document-shop tr.important {
  background: hsl(52, 100%, 86%);
  font-weight: 500;
}
body .ui.modal.document-shop tr.disabled {
  color: rgba(0, 0, 0, 0.6);
}
body .ui.modal.document-shop tr.disabled,
body .ui.modal.document-shop tr.disabled input[type="checkbox"] {
  cursor: not-allowed;
}
body .ui.modal.document-shop input[type="checkbox"] + label {
  box-sizing: border-box;
  margin-top: 2px;
}
body .ui.modal.document-shop .price-label {
  font-size: 1.2rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
body .ui.modal.document-shop .ui.message {
  margin: 0;
}
body .ui.modal.document-shop .price-label {
  margin-left: 0.5rem;
}
body .ui.modal.document-shop .dataTables_paginate {
  margin-right: 1.25rem;
}
body .ui.modal.document-shop .dataTables_wrapper > .grid > .row:first-of-type {
  padding-top: 0;
}
body .ui.modal.document-shop-preview .ui.table {
  margin-top: 0;
  margin-bottom: 0;
}
body .ui.header.document-shop-header {
  position: relative;
  padding: 2rem 2.25rem;
  border-bottom: 0;
  background: url("/ui/v1-244-10/img/premium/banner.jpg");
  background-repeat: no-repeat;
  background-position: 50% 90%;
  background-size: 100%;
}
body .ui.header.document-shop-header .icon {
  vertical-align: baseline;
}
body .ui.header.document-shop-header .sub.header {
  color: rgba(255, 255, 255, 0.9);
}
body .ui.label > a {
  opacity: 0.9;
}
body .disclaimer .small {
  font-size: 0.9rem;
}
body figure {
  height: auto;
  width: auto;
  margin: 0;
}
body figure img {
  width: 100%;
}
body .ribbon.label {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.67rem !important;
}
body .ribbon.label,
body .ribbon.label a {
  font-weight: 300 !important;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  text-transform: uppercase;
}
body .feed .ribbon.label {
  margin-top: 0.67rem !important;
}
body .search-results .feed {
  margin-top: 5px;
}
body .search-results .event.active,
body .search-results .event:hover {
  background-color: #a3b3c0;
}
body .search-results .event .label {
  margin-top: 0.25em;
  margin-left: 0.35em;
  margin-bottom: 3px;
}
body .search-results .extra.text {
  margin-top: 0 !important;
}
body .search-results .ui.feed > .event:last-child {
  padding-bottom: 3px;
}
body .search-results .ui.stackable.grid {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
body .search-results .ui.stackable.grid .map.column {
  z-index: 5;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  body .search-results .ui.stackable.grid .map.column {
    order: 1;
  }
}
body .search-results .ui.stackable.grid .map.column .export-button {
  margin-top: auto;
  align-self: flex-end;
}
body .search-results .ui.stackable.grid .map.column .export-button .ui.button {
  width: 120px;
  margin-top: 1rem;
  margin-right: 0;
}
body .more-anchor {
  margin-top: 0.9rem;
}
body .lined {
  background: url("/ui/v1-244-10/img/patterns/linedpaper.png") !important;
}
body .publication-text {
  overflow: auto;
  margin-bottom: 0.5em;
  font-family: "Roboto Mono", monospace;
  font-size: 1rem;
  line-height: 1.6;
}
body .publication-text img {
  display: none;
}
body .publication-text a,
body .publication-text a:hover,
body .publication-text a:active,
body .publication-text a *,
body .publication-text a:hover *,
body .publication-text a:active * {
  font-weight: 700 !important;
  transition: border-color 0.4s ease-in-out;
}
body .publication-text a:hover:not(.download-link),
body .publication-text a:active:not(.download-link),
body .publication-text a:hover:not(.download-link) *,
body .publication-text a:active:not(.download-link) * {
  border-bottom: 2px solid #007388;
}
body .publication-text h1,
body .publication-text h2,
body .publication-text h3,
body .publication-text h4,
body .publication-text address,
body .publication-text kbd,
body .publication-text cite {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: normal;
  font-family: "Roboto Mono", monospace;
  margin: 0px;
  font-style: normal;
}
body .publication-text h1,
body .publication-text h2 {
  font-weight: bold;
}
body .publication-text h1,
body .publication-text h2,
body .publication-text h3 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
body .publication-text h1,
body .publication-text h2,
body .publication-text h3,
body .publication-text h4 {
  text-decoration: underline;
}
body .publication-text address,
body .publication-text kbd,
body .publication-text cite {
  display: inline;
}
body .publication-text h3,
body .publication-text .lead {
  text-decoration: underline;
  font-weight: bold;
}
body .publication-text p {
  margin: 0px;
  padding: 0px;
}
body .publication-text kbd {
  font-size: 1rem;
}
body .publication-text .line {
  min-height: 1.6;
}
body .publication-text th,
body .publication-text td {
  vertical-align: top;
}
body .publication-text th + th,
body .publication-text td + td {
  padding-left: 15px;
}
body .general-information.ui.list {
  margin: 0 0 1em;
}
body .general-information.ui.list + .general-information.ui.list {
  margin-top: 2em;
}
body .general-information.ui.list .item {
  display: flex;
  align-items: center;
}
body .general-information.ui.list .item .icon {
  color: #a3b3c0;
  font-size: 90%;
}
body .general-information.ui.list .item .ui.circular.label {
  flex: 0 0 auto;
  font-weight: 500;
  border-radius: 500rem;
  margin: -0.5em 0.5em -0.5em -0.5em;
  padding-left: 0.75em !important;
  padding-right: 0.75em !important;
  cursor: default;
}
body .general-information.ui.list .item .icon + .content {
  padding-left: 0.25em;
}
body .general-information.ui.list .former.item:first-child .content {
  font-size: 1em;
}
body .general-information.ui.list .former.item .content {
  font-size: 90%;
  color: rgba(0, 0, 0, 0.77);
}
body .general-information.ui.list .former.item .content > a {
  color: rgba(0, 0, 0, 0.4);
}
body .general-information.ui.list .former.item .content > a:hover {
  color: rgba(0, 0, 0, 0.6);
}
body .general-information.ui.list .former.item .content.strong {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
input::-ms-reveal {
  display: none;
}
main {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
main address {
  display: inline;
  font-style: normal;
}
.ad-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ad-container ins {
  width: 100%;
}
.ad-container-bottom {
  padding: 0;
  border: none !important;
  width: 100%;
  min-height: 100px;
}
.ad-container-bottom > ins {
  display: block;
}
.ad-container-top {
  padding: 0;
  border: none !important;
  width: 100%;
  min-height: 100px;
}
.ad-container-top > ins {
  display: block;
}
@media (max-width: 767.98px) {
  .ad-container-bottom,
  .ad-container-top {
    min-height: 250px;
  }
}
.ad-container-right,
.ad-container-right2 {
  display: none;
}
.ui.ribbon.label:after {
  display: none;
}
@media (min-width: 768px) {
  .ui.ribbon.label:after {
    display: block;
  }
  [data-ads="true"] main {
    padding-right: 120px;
  }
  [data-ads="true"] main > .content {
    margin-right: 27px;
    min-height: 600px;
  }
  [data-ads="true"] main .ad-container-right {
    display: flex;
    padding: 0;
    border: none !important;
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  [data-ads="true"] main .ad-container-right,
  [data-ads="true"] main .ad-container-right > ins {
    width: 120px;
    height: 600px;
  }
  [data-ads="true"] main .ad-container-right > ins {
    display: block;
  }
  [data-ads="false"] main {
    padding-right: 0;
  }
  [data-ads="false"] main > .content {
    margin-right: 0;
    min-height: 600px;
  }
  [data-ads="true"] main .ad-container-right2 {
    border: none;
    background: #e6e5ea;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    padding: 0;
    border: none !important;
    display: flex !important;
    width: 300px;
    height: 600px;
    position: absolute !important;
    top: 615px;
    right: 0;
    overflow: hidden;
  }
  [data-ads="true"] main .ad-container-right2,
  [data-ads="true"] main .ad-container-right2 > ins {
    width: 120px;
    height: 600px;
  }
  [data-ads="true"] main .ad-container-right2 > ins {
    display: block;
  }
  [data-ads="true"] main > .content {
    min-height: 1220px !important;
  }
}
@media (min-width: 992px) {
  [data-ads="true"] main {
    padding-right: 160px;
  }
  [data-ads="true"] main > .content {
    margin-right: 27px;
    min-height: 600px;
  }
  [data-ads="true"] main .ad-container-right {
    display: flex;
    padding: 0;
    border: none !important;
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  [data-ads="true"] main .ad-container-right,
  [data-ads="true"] main .ad-container-right > ins {
    width: 160px;
    height: 600px;
  }
  [data-ads="true"] main .ad-container-right > ins {
    display: block;
  }
  [data-ads="false"] main {
    padding-right: 0;
  }
  [data-ads="false"] main > .content {
    margin-right: 0;
    min-height: 600px;
  }
  [data-ads="true"] main .ad-container-right2 {
    border: none;
    background: #e6e5ea;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    padding: 0;
    border: none !important;
    display: flex !important;
    width: 300px;
    height: 600px;
    position: absolute !important;
    top: 615px;
    right: 0;
    overflow: hidden;
  }
  [data-ads="true"] main .ad-container-right2,
  [data-ads="true"] main .ad-container-right2 > ins {
    width: 160px;
    height: 600px;
  }
  [data-ads="true"] main .ad-container-right2 > ins {
    display: block;
  }
}
@media (min-width: 1200px) {
  [data-ads="true"] main {
    padding-right: 300px;
  }
  [data-ads="true"] main > .content {
    margin-right: 27px;
    min-height: 600px;
  }
  [data-ads="true"] main .ad-container-right {
    display: flex;
    padding: 0;
    border: none !important;
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  [data-ads="true"] main .ad-container-right,
  [data-ads="true"] main .ad-container-right > ins {
    width: 300px;
    height: 600px;
  }
  [data-ads="true"] main .ad-container-right > ins {
    display: block;
  }
  [data-ads="false"] main {
    padding-right: 0;
  }
  [data-ads="false"] main > .content {
    margin-right: 0;
    min-height: 600px;
  }
  [data-ads="true"] main .ad-container-right2 {
    border: none;
    background: #e6e5ea;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    padding: 0;
    border: none !important;
    display: flex !important;
    width: 300px;
    height: 600px;
    position: absolute !important;
    top: 615px;
    right: 0;
    overflow: hidden;
  }
  [data-ads="true"] main .ad-container-right2,
  [data-ads="true"] main .ad-container-right2 > ins {
    width: 300px;
    height: 600px;
  }
  [data-ads="true"] main .ad-container-right2 > ins {
    display: block;
  }
}
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .mobile-hide {
    display: none;
  }
  .person-pub-list {
    overflow: hidden;
  }
}
.drill-downs .secondary.menu {
  margin-top: 0;
  position: relative;
}
.drill-downs .tab-content .tab {
  margin-top: 0 !important;
}
@media screen {
  .drill-downs .tab-content {
    position: relative;
    height: 100px;
    padding-bottom: 56.25%;
  }
  .drill-downs .tab-content .ui.tab {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: inherit;
    visibility: hidden;
  }
  .drill-downs .tab-content .ui.tab.active {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  [data-ads="false"] .drill-downs .tab-content {
    padding-bottom: 31.25%;
  }
}
@media screen {
  .bar-charts .tab-content .ui.tab {
    position: absolute;
    left: 0;
    height: 270px;
    display: inherit;
    visibility: hidden;
  }
  .bar-charts .tab-content .ui.tab.active {
    visibility: visible;
  }
  .bar-charts .tab-content .note {
    padding-left: 11px;
  }
  .bar-charts .tab-content.has-bar-charts {
    min-height: 270px;
  }
  .bar-charts .tab-content .ui.tab + .note {
    padding-top: 265px;
  }
}
@media (min-width: 768px) {
  .bar-charts .menu {
    border-top: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .bar-charts .menu .item {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}
.bar-charts .tab-content .tab {
  width: 100%;
}
.bar-charts .menu {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  overflow: hidden;
  flex-wrap: wrap;
  min-height: 0;
  height: 37px;
}
@media screen {
  .print-only {
    display: none !important;
  }
}
@media print {
  .print-only {
    display: inherit !important;
  }
  font-size: 16px;
  body {
    overflow-x: auto !important;
    height: auto !important;
    background-color: transparent !important;
    background-image: none !important;
    color: black !important;
  }
  a,
  a:hover,
  a:active,
  button,
  button:hover,
  button:active,
  a *,
  a:hover *,
  a:active *,
  button *,
  button:hover *,
  button:active * {
    font-weight: 300 !important;
    color: black !important;
  }
  .screen-only,
  header nav,
  .claim,
  .ad-container,
  .ad-container-top-deco,
  .ad-container-bottom-deco,
  .ui.primary.segment,
  .ui.button,
  .cc_banner-wrapper,
  .tooltip-host,
  [data-layout="sheet"] .icon.download {
    display: none !important;
  }
  button.shake,
  button.print {
    display: none !important;
  }
  main {
    border: none !important;
  }
  h1 .icon {
    display: none !important;
  }
  h1 .content {
    padding-left: 0 !important;
  }
  h2.break-before {
    page-break-before: always;
  }
  .segments {
    border: none !important;
    box-shadow: none !important;
  }
  .segment {
    padding: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-top: none !important;
    margin-top: 2rem !important;
  }
  h2 + .segment {
    margin-top: 0rem !important;
  }
  .ui.segment.secondary {
    background-color: transparent !important;
    border: none !important;
  }
  .disclaimer {
    padding-top: 0 !important;
    border: none !important;
  }
  .ui.ribbon.label {
    font-size: 115%;
    left: 0 !important;
    margin-left: 0 !important;
    margin-top: 1rem !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: black !important;
    float: none !important;
    text-transform: uppercase;
    margin-bottom: 1.5rem !important;
  }
  .ui.ribbon.label:after {
    display: none;
  }
  h4 {
    color: black !important;
    font-size: 110% !important;
  }
  .board .column {
    width: 100% !important;
  }
  .event .ui.header,
  .pubTable.feed .event,
  .segment.financials,
  .segment.sheets,
  .segment.history,
  .segment.network {
    page-break-inside: avoid;
  }
  [data-layout="barChart"] {
    page-break-inside: auto;
    display: table;
  }
  [data-layout="barChart"] .chart {
    width: 50%;
    display: inline-block;
    height: 270px;
    page-break-inside: avoid;
  }
  [data-layout="barChart"] .chart:nth-child(odd) {
    padding-right: 4px;
  }
  [data-layout="barChart"] .chart:nth-child(even) {
    padding-left: 4px;
  }
  [data-layout="barChart"] .chart svg {
    overflow: visible;
  }
  h2,
  h3,
  h4 {
    page-break-after: avoid;
  }
  [data-layout="pubTable"] {
    page-break-inside: auto;
  }
  [data-layout="drillDown"] .box {
    width: 50%;
    page-break-inside: avoid;
    box-shadow: none !important;
    border: 1px solid black !important;
  }
  [data-layout="drillDown"] .box .value,
  [data-layout="drillDown"] .box .desc {
    text-shadow: none !important;
  }
  .ui.tab {
    display: inherit;
  }
  .ui.table {
    margin-bottom: 1.5rem !important;
  }
  .printable {
    display: inherit !important;
  }
  .publication-markup {
    text-align: justify;
    text-justify: newspaper;
    margin-bottom: 1.5rem;
  }
  .publication-markup img {
    display: none;
  }
  .publication-markup span {
    font-weight: 500;
  }
  .publication-markup .lead {
    text-decoration: underline;
  }
  .publication-text {
    overflow: visible !important;
  }
  [data-layout="graph"] svg .link .desc {
    opacity: 1 !important;
  }
  [data-layout="financials"] {
    overflow: visible !important;
  }
  [data-layout="sheet"] .source,
  [data-layout="financials"] .source,
  [data-layout="sheet"] .auditor,
  [data-layout="financials"] .auditor,
  [data-layout="sheet"] .note,
  [data-layout="financials"] .note {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  [data-layout="sheet"] .ui.bizq.table tbody .closed td:first-child:after {
    content: none;
  }
  [data-layout="sheet"] .ui.bizq.table tbody .expanded td:first-child:after {
    content: none;
  }
  table.styled * {
    box-sizing: border-box;
  }
  table.styled,
  table.styled thead,
  table.styled tbody {
    display: block;
  }
  table.styled tr {
    display: flex;
  }
  table.styled th,
  table.styled td {
    width: 15%;
  }
  table.styled th:first-child,
  table.styled td:first-child {
    width: 25%;
    min-width: auto !important;
  }
  .ui.grey.segment,
  .ui.grey.segment:not(.inverted) {
    border-top: none !important;
  }
  [data-ads="true"] main {
    display: none !important;
  }
}
.print {
  font-size: 16px;
}
.print .print-only {
  display: inherit !important;
}
.print body {
  overflow-x: auto !important;
  height: auto !important;
  background-color: transparent !important;
  background-image: none !important;
  color: black !important;
}
.print a,
.print a:hover,
.print a:active,
.print button,
.print button:hover,
.print button:active,
.print a *,
.print a:hover *,
.print a:active *,
.print button *,
.print button:hover *,
.print button:active * {
  font-weight: 300 !important;
  color: black !important;
}
.print .screen-only,
.print header nav,
.print .claim,
.print .ad-container,
.print .ad-container-top-deco,
.print .ad-container-bottom-deco,
.print .ui.primary.segment,
.print .ui.button,
.print .cc_banner-wrapper,
.print .tooltip-host,
.print [data-layout="sheet"] .icon.download {
  display: none !important;
}
.print button.shake,
.print button.print {
  display: none !important;
}
.print main {
  border: none !important;
}
.print h1 .icon {
  display: none !important;
}
.print h1 .content {
  padding-left: 0 !important;
}
.print h2.break-before {
  page-break-before: always;
}
.print .segments {
  border: none !important;
  box-shadow: none !important;
}
.print .segment {
  padding: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-top: none !important;
  margin-top: 2rem !important;
}
.print h2 + .segment {
  margin-top: 0rem !important;
}
.print .ui.segment.secondary {
  background-color: transparent !important;
  border: none !important;
}
.print .disclaimer {
  padding-top: 0 !important;
  border: none !important;
}
.print .ui.ribbon.label {
  font-size: 115%;
  left: 0 !important;
  margin-left: 0 !important;
  margin-top: 1rem !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: black !important;
  float: none !important;
  text-transform: uppercase;
  margin-bottom: 1.5rem !important;
}
.print .ui.ribbon.label:after {
  display: none;
}
.print h4 {
  color: black !important;
  font-size: 110% !important;
}
.print .board .column {
  width: 100% !important;
}
.print .event .ui.header,
.print .pubTable.feed .event,
.print .segment.financials,
.print .segment.sheets,
.print .segment.history,
.print .segment.network {
  page-break-inside: avoid;
}
.print [data-layout="barChart"] {
  page-break-inside: auto;
  display: table;
}
.print [data-layout="barChart"] .chart {
  width: 50%;
  display: inline-block;
  height: 270px;
  page-break-inside: avoid;
}
.print [data-layout="barChart"] .chart:nth-child(odd) {
  padding-right: 4px;
}
.print [data-layout="barChart"] .chart:nth-child(even) {
  padding-left: 4px;
}
.print [data-layout="barChart"] .chart svg {
  overflow: visible;
}
.print h2,
.print h3,
.print h4 {
  page-break-after: avoid;
}
.print [data-layout="pubTable"] {
  page-break-inside: auto;
}
.print [data-layout="drillDown"] .box {
  width: 50%;
  page-break-inside: avoid;
  box-shadow: none !important;
  border: 1px solid black !important;
}
.print [data-layout="drillDown"] .box .value,
.print [data-layout="drillDown"] .box .desc {
  text-shadow: none !important;
}
.print .ui.tab {
  display: inherit;
}
.print .ui.table {
  margin-bottom: 1.5rem !important;
}
.print .printable {
  display: inherit !important;
}
.print .publication-markup {
  text-align: justify;
  text-justify: newspaper;
  margin-bottom: 1.5rem;
}
.print .publication-markup img {
  display: none;
}
.print .publication-markup span {
  font-weight: 500;
}
.print .publication-markup .lead {
  text-decoration: underline;
}
.print .publication-text {
  overflow: visible !important;
}
.print [data-layout="graph"] svg .link .desc {
  opacity: 1 !important;
}
.print [data-layout="financials"] {
  overflow: visible !important;
}
.print [data-layout="sheet"] .source,
.print [data-layout="financials"] .source,
.print [data-layout="sheet"] .auditor,
.print [data-layout="financials"] .auditor,
.print [data-layout="sheet"] .note,
.print [data-layout="financials"] .note {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.print [data-layout="sheet"] .ui.bizq.table tbody .closed td:first-child:after {
  content: none;
}
.print [data-layout="sheet"] .ui.bizq.table tbody .expanded td:first-child:after {
  content: none;
}
.print table.styled * {
  box-sizing: border-box;
}
.print table.styled,
.print table.styled thead,
.print table.styled tbody {
  display: block;
}
.print table.styled tr {
  display: flex;
}
.print table.styled th,
.print table.styled td {
  width: 15%;
}
.print table.styled th:first-child,
.print table.styled td:first-child {
  width: 25%;
  min-width: auto !important;
}
.print .ui.grey.segment,
.print .ui.grey.segment:not(.inverted) {
  border-top: none !important;
}
.admin-bar {
  position: fixed;
  right: 15px;
  bottom: 15px;
  padding: 15px;
  background: white;
  border: 1px solid grey;
  z-index: 1000;
}
.admin-bar strong {
  display: block;
  margin: 0 0 5px;
}
.admin-bar .navigation {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}
.admin-bar .navigation li {
  display: inline-block;
  border-left: 1px solid;
  padding: 0 5px;
  text-transform: capitalize;
}
.admin-bar .navigation li:first-child {
  border-left: none;
  padding-left: 0;
}
strike {
  position: relative;
  text-decoration: none;
  display: inline-block;
}
strike:after {
  position: absolute;
  width: 100%;
  content: " ";
  left: 0%;
  top: 0;
  height: 70%;
  border-bottom: 0.95em solid #999;
}
[data-layout="barChart"] .tick line {
  stroke-width: 1;
  stroke-opacity: 1;
  stroke: rgba(0, 0, 0, 0.1);
}
[data-layout="barChart"] .y-axis .zero line {
  stroke: #000 !important;
  stroke-width: 1px;
  stroke-opacity: 1;
}
[data-layout="barChart"] .y-axis .domain {
  stroke: #000;
  stroke-width: 1px;
  stroke-opacity: 1;
}
[data-layout="barChart"] .tick text {
  fill: #000 !important;
  font-weight: 300;
}
[data-layout="barChart"] .x-axis .tick text {
  transform: translateY(0.3em);
  font-size: 0.8rem !important;
}
[data-layout="barChart"] p {
  font-size: 90%;
}
[data-layout="barChart"] p [data-clickable="true"] {
  color: #007388;
  cursor: pointer;
}
[data-layout="history"],
[data-layout="vita"] {
  min-height: 24px;
}
[data-layout="history"] .tick text,
[data-layout="vita"] .tick text {
  fill: #000 !important;
  font-weight: 300;
  transform: translateY(0.3em);
}
[data-layout="history"] .tick line,
[data-layout="vita"] .tick line,
[data-layout="history"] .domain,
[data-layout="vita"] .domain {
  stroke: #000 !important;
  stroke-width: 1px;
  stroke-opacity: 1;
}
[data-layout="history"] .grid .tick line,
[data-layout="vita"] .grid .tick line,
[data-layout="history"] line.grid,
[data-layout="vita"] line.grid {
  stroke-width: 1 !important;
  stroke-opacity: 1 !important;
  stroke: rgba(0, 0, 0, 0.1) !important;
}
[data-layout="history"] .link-layer path[data-highlighted="true"],
[data-layout="vita"] .link-layer path[data-highlighted="true"] {
  stroke-width: 3;
}
[data-layout="history"] .label-g a text,
[data-layout="vita"] .label-g a text {
  font-weight: 300 !important;
}
[data-layout="drillDown"] .legends .legend1 {
  font-weight: 300 !important;
}
[data-layout="drillDown"] .box-parent .box .value {
  font-weight: 300;
}
[data-layout="sheet"] {
  font-size: 90%;
  line-height: 1em;
  overflow-x: auto;
}
[data-layout="sheet"] th:first-child,
[data-layout="sheet"] td:first-child {
  font-weight: 500 !important;
  background: #f9fafb !important;
}
[data-layout="sheet"] tfoot td:first-child {
  font-weight: 500 !important;
}
[data-layout="sheet"] thead th,
[data-layout="sheet"] tbody [data-summary="true"] td:first-child,
[data-layout="sheet"] tbody [data-level="0"] td:first-child {
  font-weight: 500 !important;
}
.ui.ribbon.label .info.icon {
  box-shadow: 0 0 0 0.08em #a3b3c0 inset;
  font-size: 0.65em;
  color: white;
  margin-left: 1em;
  margin-right: 0;
}
body .financials h2.ui.ribbon.label {
  margin-bottom: 1em !important;
}
[data-layout="financials"] {
  line-height: 1em;
  overflow-x: auto;
}
[data-layout="financials"] th:first-child,
[data-layout="financials"] td:first-child {
  font-weight: 500 !important;
  background: #f9fafb !important;
}
[data-layout="financials"] tfoot td:first-child {
  font-weight: 500 !important;
}
[data-layout="financials"] .source {
  display: block;
}
.ui.dimmer.modals {
  background-color: rgba(0, 0, 0, 0.65);
}
.questionnaire .icon {
  font-size: 2.5em !important;
}
.questionnaire > .content {
  font-size: 1.1rem !important;
}
.questionnaire > .content .ui.list .item {
  margin-bottom: 8px;
}
.questionnaire > .content .ui.list i {
  width: 60px;
  vertical-align: middle;
}
.questionnaire > .content .ui.list .content {
  vertical-align: middle;
}
.questionnaire .ui.form .ui.checkbox {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 0.5em;
}
.questionnaire .ui.form .field:last-child {
  margin-bottom: 0;
}
.questionnaire .sub.header {
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 300 !important;
  font-size: 1.2rem !important;
  margin-top: 3px !important;
}
.modal:not(.legacy) {
  margin: 0 !important;
}
.modal .header {
  border-bottom-width: 1.5px !important;
}
.modal .content .snippets {
  font-size: 80% !important;
}
.modal .content .snippets th {
  padding-right: 0.5em !important;
}
.modal .content .snippets b {
  font-weight: 500 !important;
}
.modal .content .snippets .snippet img {
  display: none;
}
.modal .content .snippets .snippet + .snippet {
  margin-top: 1em;
}
.ui.modal > .actions.left-item {
  display: flex;
}
@media (min-width: 550px) {
  .ui.modal > .actions.left-item > :first-child {
    margin-right: auto;
  }
}
@media (max-width: 549px) {
  .ui.modal > .actions.left-item {
    flex-direction: column;
    align-items: end;
  }
  .ui.modal > .actions.left-item > * {
    margin-bottom: 0.5rem;
  }
}
.ui.modal > .actions {
  background: #f9fafb;
  padding: 1rem 1rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
}
.ui.modal .actions > .button {
  margin-left: 0.75em;
}
.pubTable .ui.feed > .event > .content .date {
  color: #000;
  font-weight: 300;
  font-size: 0.9rem;
}
.ui.feed > .event > .label .circular.icon {
  background-color: #f5f5f5;
}
.ui.inverted.dimmer {
  background-color: transparent;
}
.contact {
  height: 80px;
}
.contact .grid {
  display: none;
}
.contact [data-field-container] {
  margin-bottom: 0.7em;
  margin-top: 0.2em;
  display: none;
}
.contact2 .people-container {
  display: none;
  max-height: 240px;
  overflow-y: auto;
}
.network {
  width: auto;
  height: 80px;
  position: relative;
  transition: height 0.5s;
}
.network .shake {
  display: none;
}
.network [data-layout="graph"],
.network [data-layout="dendrogram"] {
  position: absolute;
  top: 1em;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.network [data-layout="graph"] .shake {
  bottom: 1rem;
  right: 1rem;
  position: absolute;
  display: inherit;
}
.network .link:not(:hover)[data-highlight="root"] .desc-bg,
.network .link:not(:hover)[data-highlight="node"] .desc-bg {
  fill: #e6e5e9 !important;
}
.network .link:not(:hover)[data-highlight="root"] .desc,
.network .link:not(:hover)[data-highlight="node"] .desc {
  font-weight: 500 !important;
}
.network .link:hover .desc {
  font-weight: 500 !important;
}
.tariff-box {
  background-color: white;
  padding: 15px;
  font-size: 15px;
}
@media (min-width: 768px) {
  .tariff-box {
    margin-left: 30px;
  }
}
.tariff-box .item {
  margin: 5px 0;
}
.tariff-box .item .icon {
  padding: 0 25px 0 5px;
  color: #81b08c;
  font-size: 18px;
}
.tariff-box > .header {
  color: #007388;
}
.tariff-box .sub.header {
  font-size: 16px !important;
}
.tariff-box .content {
  font-weight: 500;
}
.tariff-box .content a {
  color: black;
}
.tariff-box .order,
.tariff-box p.small,
.tariff-box .price {
  text-align: center;
}
.tariff-box .price {
  margin-bottom: 10px;
}
.tariff-box .price > * {
  display: inline-block;
  margin: 5px 5px;
}
.tariff-box .price .value {
  font-family: "Josefin Sans", serif;
  font-weight: bold;
  font-size: 40px;
}
.collapse-container p {
  display: inline;
}
.carousel-container {
  position: relative;
}
.carousel-container .carousel-content {
  position: relative;
  overflow: hidden;
}
.carousel-container .carousel-nav {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 1rem;
  z-index: 2;
}
.carousel-container .carousel-nav-button {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.6);
  width: 16px;
  height: 16px;
  margin: 0 4px;
  border: 0;
  border-radius: 50%;
}
.carousel-container .carousel-nav-button.active {
  background: rgba(255, 255, 255, 0.9);
}
.carousel-container .carousel-button {
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-top-width: 20px;
  border-bottom-width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  z-index: 2;
  padding: 0;
}
.carousel-container .carousel-button.carousel-button-left {
  left: -12px;
  border-right: 35px solid rgba(0, 115, 136, 0.9);
}
.carousel-container .carousel-button.carousel-button-left:hover {
  border-right-color: rgba(0, 115, 136, 0.75);
}
.carousel-container .carousel-button.carousel-button-right {
  right: -12px;
  border-left: 35px solid rgba(0, 115, 136, 0.9);
}
.carousel-container .carousel-button.carousel-button-right:hover {
  border-left-color: rgba(0, 115, 136, 0.75);
}
.carousel-container .carousel-page {
  position: absolute;
  width: 100%;
  height: max-content;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem 50px;
  background-color: #f5f5f5;
  background-size: cover;
}
.carousel-container .carousel-page.active {
  z-index: 1;
}
.journalism-container {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .journalism-container {
    display: flex;
    justify-content: space-between;
  }
  .journalism-container .carousel-container {
    width: 68.75%;
    margin-right: 1rem;
  }
}
.journalism-container .carousel-content {
  min-height: 600px;
}
.journalism-container .journalism-article-header {
  background: rgba(255, 255, 255, 0.55);
  margin: 0;
  padding: 1rem;
  border-bottom: 4px solid #007388;
  border-radius: 8px 8px 0 0;
}
.journalism-container .journalism-article {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  flex-grow: 1;
  padding: 1rem 1rem 3rem;
  border-radius: 0 0 8px 8px;
}
.journalism-container .journalism-article-label {
  position: absolute;
  min-width: 180px;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  margin: 2px;
  padding: 1rem;
  background: #007388;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  line-height: 1;
}
.journalism-container .journalism-statistics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Josefin Sans", serif;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.35em;
  color: white;
  padding: 1.5rem;
  background-color: #007388;
}
@media (min-width: 992px) {
  .journalism-container .journalism-statistics {
    width: 31.25%;
    min-height: 450px;
  }
}
.journalism-container .journalism-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  color: #294755;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 2rem auto;
}
.logo-container {
  height: 70px;
  display: flex !important;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.logo-nr img {
  max-height: 100%;
  width: auto;
  object-fit: contain;
}
.logo-icij img {
  max-height: 100%;
  width: auto;
  border-right: 6px solid #fff !important;
  display: block;
}
.logo-correl img {
  max-width: 100%;
  margin: auto;
  width: auto;
  height: auto;
}
body .header-box.segments {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
body .header-box.segments .ui.segment {
  margin-top: 0;
  margin-bottom: 0;
}
body .ui.header-box.segment.small {
  padding: 1rem !important;
}
body .ui.header-box.segment .ui.header {
  color: #fff;
}
.signup-welcome .ui.info.segment {
  margin-bottom: 0;
}
.signup-welcome .signup-footer {
  display: block;
  width: 100%;
  height: 191px;
  margin-bottom: 1rem;
  background-image: url(https://blog.northdata.de/hubfs/KFMz6LPw.jpeg);
  background-position-x: 5%;
}
.ui.grid.compact-columns > .column {
  width: 50% !important;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991.98px) {
  .ui.grid.compact-columns > .column {
    width: 100% !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
.ui.grid.compact-columns > .column:first-of-type {
  padding-right: 0.1rem;
}
.ui.grid.compact-columns > .column:last-of-type {
  padding-left: 0.1rem;
}
.ui.grid.compact-columns > .column .page {
  display: none;
  height: 100%;
}
.ui.grid.compact-columns > .column .active.page {
  display: flex;
  flex-direction: column;
}
.ui.grid.compact-columns > .column .ui.content.segment {
  height: 100%;
  flex-grow: 1;
  background-color: #f5f5f5;
  padding: 1rem 1.5rem;
}
.ui.grid.compact-columns > .column .ui.content.segment .info-hint {
  margin-top: 0.5rem;
  text-align: center;
}
.ui.grid.compact-columns > .column h2 {
  font-size: 1.4rem;
  text-transform: none;
  margin-top: 0;
  clear: both;
}
.ui.grid.compact-columns > .column .info-box:not(:first-child) {
  margin-top: 2rem;
}
.selfcare-portal .ui.grid > .column {
  min-height: 650px;
}
.selfcare-portal .loading-content {
  height: 650px;
  padding: 3rem;
  margin: -1rem;
}
.selfcare-portal .ui.inline.loader.active {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.selfcare-portal .detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  font-weight: 500;
  white-space: nowrap;
}
.selfcare-portal .detail-item > .ui.label {
  width: 30%;
  text-align: right;
  padding-left: 0.4rem;
  margin-right: 0.75em;
  border-right: 2px solid #007388;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  font-weight: 500;
  white-space: normal;
  flex: 0 0 auto;
}
.selfcare-portal .detail-item > div {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selfcare-portal .ui.content.segment {
  display: flex;
  flex-direction: column;
}
.selfcare-portal .current-subscription {
  margin-bottom: auto;
}
.selfcare-portal .right-buttons {
  display: flex;
  justify-content: flex-end;
}
.selfcare-portal .wide-button {
  width: 180px;
}
.ui.modal.selfcare-portal-modal > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#customer-modal .ui.form,
#payment-modal .ui.form,
#users-modal .ui.form {
  min-height: 500px;
}
#payment-modal .payment-form .field i.circular.icon {
  font-size: 0.65em !important;
}
#payment-modal .payment-form .credit-card-form .inline.fields {
  justify-content: flex-end;
  margin: 0 -0.5em;
}
#payment-modal .payment-form .credit-card-form .inline.fields > .field {
  padding: 0 0.5em;
}
#payment-modal .payment-form .credit-card-form .inline.fields > .field > .ui.dropdown {
  width: 100%;
}
#users-modal .users-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#users-modal .users-form .fields {
  align-items: center;
}
#users-modal .users-form .field {
  flex-grow: 1;
}
#users-modal .users-form .add-user {
  margin: 0 auto 1rem auto;
}
#users-modal .users-form i.link.icon {
  font-size: 1rem;
  margin-right: 0.5em;
}
#upgrade-modal .ui.icon.message > .icon {
  width: 4rem;
  align-self: center;
}
#upgrade-modal .buttons {
  display: flex;
  flex-direction: column;
  align-items: end;
}
#upgrade-modal .buttons button {
  margin-right: 0;
}
#upgrade-modal .price {
  display: flex;
  align-items: center;
  min-height: 3rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
#upgrade-modal .price label {
  display: inline-block;
  margin-left: 8px;
}
#upgrade-modal .price .value {
  font-size: 3rem;
  font-family: "Josefin Sans", serif;
  font-weight: bold;
}
@media (max-width: 700px) {
  #upgrade-modal .ui.icon.message {
    flex-direction: column;
    align-items: center;
  }
  #upgrade-modal .buttons {
    align-items: center;
  }
  #upgrade-modal .price,
  #upgrade-modal .content {
    margin-top: 1rem;
  }
}
#upgrade-summary-modal .line {
  font-size: 0.9rem;
  text-align: right;
}
.signup .ui.grid > .column {
  min-height: 700px;
}
.signup .invoice-data {
  font-family: "Josefin Sans", serif;
  max-width: 50ch;
  white-space: nowrap;
}
.signup .invoice-data * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.signup .coupon-code {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.signup .coupon-code .ui.input > input {
  text-transform: uppercase;
}
.signup .coupon-code .ui.input > .ui.label {
  font-weight: 500;
}
.signup .coupon-code .ui.input > i.icon {
  width: 1.75em;
  font-size: 1.5em;
}
.signup .coupon-code .ui.input > i.icon.check {
  color: #007388;
}
.signup .coupon-code .ui.input > i.icon.delete {
  color: #af4e4a;
}
.signup .error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 25%;
  left: 50%;
  width: 85% !important;
  margin: 0 !important;
  text-align: center;
  font-size: 1.14em;
  transform: translateX(-50%) translateY(-50%);
}
.signup #order-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.signup #order-summary table .description {
  font-size: 0.65rem;
  line-height: 0.65rem;
}
.signup #order-summary .scroll-table {
  overflow: auto hidden;
  padding-bottom: 0.6rem;
}
.signup #order-summary > .summary .line {
  font-size: 0.9rem;
  text-align: right;
}
.signup .buttons {
  display: none;
  margin: 0.5rem auto;
  padding: 0 4rem;
}
.signup .buttons.active {
  display: block;
}
.signup .buttons .ui.button {
  width: 100%;
  margin-top: 0.5rem;
}
.smallshake.transition {
  animation-duration: 400ms;
  animation-name: smallshake;
}
@keyframes smallshake {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}
[data-ads="true"] .grid.product-news > .column {
  width: 50% !important;
}
@media (max-width: 991.98px) {
  [data-ads="true"] .grid.product-news > .column {
    width: 100% !important;
  }
}
[data-ads="false"] .grid.product-news > .column {
  width: 50% !important;
}
@media (max-width: 767.98px) {
  [data-ads="false"] .grid.product-news > .column {
    width: 100% !important;
  }
}
.product-statistics {
  font-family: "Josefin Sans", serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.product-statistics > .statistics {
  display: flex;
  margin-top: 6px;
}
.product-statistics > .ui.label {
  width: 312px;
  height: 60px;
  border-radius: 30px;
}
.product-statistics > .statistics > .ui.label {
  width: 100px;
  height: 100px;
  margin: 0 2px;
  border-radius: 50%;
}
.product-statistics .ui.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
}
.product-statistics .ui.label .label {
  font-size: 1rem;
}
.product-statistics .ui.label .value {
  font-size: 1.9rem;
}
@media (min-width: 992px) {
  [data-ads="false"] .product-statistics > .ui.label {
    width: 400px;
  }
  [data-ads="false"] .product-statistics > .statistics > .ui.label {
    width: 120px;
    height: 120px;
    margin: 0 10px;
  }
}
@media (min-width: 1200px) {
  [data-ads="false"] .product-statistics > .ui.label {
    width: 440px;
  }
  [data-ads="false"] .product-statistics > .statistics > .ui.label {
    margin: 0 20px;
  }
}
.animate-badge {
  animation: badge-grow 2.25s ease-in-out;
}
@keyframes badge-grow {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
.legal section {
  margin-bottom: 15px;
}
.legal section {
  counter-increment: section;
}
.legal h3::before {
  content: counter(section) ". ";
}
.legal section p {
  counter-increment: paragraph;
}
.legal section p::before {
  content: counter(section) "." counter(paragraph) " ";
}
table.watches button {
  margin: 5px 10px;
}
table.ui.ui.ui.ui.company-representatives .table-header {
  display: flex;
  align-items: center;
  gap: 1rem 0.5rem;
  flex-wrap: wrap;
}
table.ui.ui.ui.ui.company-representatives .table-header > .ui.label {
  text-transform: uppercase;
}
table.ui.ui.ui.ui.company-representatives .table-header > .ui.input {
  margin-left: auto;
}
@media (max-width: 767.98px) {
  table.ui.ui.ui.ui.company-representatives tr td.role-hidden {
    display: none !important;
  }
}
table.ui.ui.ui.ui.company-representatives tr td.role-hidden > span {
  visibility: hidden;
}
.ad-bottom.quartermedia-tag {
  margin-left: -15px;
  margin-right: -15px;
}
main .ui.secondary.menu {
  overflow-x: hidden;
}
.ui.top.attached.pointing.secondary.menu .item {
  margin-bottom: 0px;
  padding: 10px !important;
}
.search-select {
  overflow-x: visible !important;
}
.ui.segment.coverage .ui.grid {
  margin-top: 0;
}
.ui.segment.coverage .coverage-container {
  margin-top: 4rem;
}
.ui.segment.coverage .ui.menu .item {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.ui.segment.coverage .filters {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem 1rem;
}
@media (max-width: 767.98px) {
  .ui.segment.coverage .filters {
    flex-wrap: wrap;
  }
}
.ui.segment.coverage .filters > div {
  width: 350px;
}
@media (max-width: 767.98px) {
  .ui.segment.coverage .filters > div {
    width: 100%;
  }
}
.ui.segment.coverage .ui.coverage.table {
  margin: 0.5rem 0 2rem;
}
.ui.segment.coverage .indicators.table .country-list {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
@media (min-width: 768px) {
  .ui.table > tbody > tr > td.centered-cell,
  .ui.table > tr > td.centered-cell {
    text-align: center;
  }
}
.ui.source.label {
  min-width: 40px;
  font-weight: 500;
  cursor: default;
  box-sizing: border-box;
}
.ui.source.label.ref {
  display: inline-block;
  margin: 1px 2px;
}
.ui.indicator.label {
  display: inline;
  font-weight: 500;
}
.ui.label.inno-tag::after {
  content: none;
}
div.zefix table,
div.dm table,
div.lei table,
div.fk table,
div.dp table,
div.hsk table,
div.chp table,
div.psc table,
div.ukri table,
div.ukrlp table,
div.roc table,
div.rocar table,
div.rocev table,
div.scr table,
div.scrar table,
div.ccni table,
div.ccniar table,
div.bod table,
div.sir table,
div.inpi table,
div.inpibil table,
div.rne table,
div.rnebs table,
div.pees table,
div.cvrcom table,
div.cvrpc table,
div.cvrreg table,
div.dkcom table,
div.dkper table,
div.nocom table,
div.noper table,
div.ccrcom table,
div.ccrrol table,
div.secom table,
div.seper table,
div.epo table,
div.euipotm table,
div.eutr table,
div.bkarta table,
div.radon table {
  width: 100%;
}
div.zefix td.key,
div.dm td.key,
div.lei td.key,
div.fk td.key,
div.dp td.key,
div.hsk td.key,
div.chp td.key,
div.psc td.key,
div.ukri td.key,
div.ukrlp td.key,
div.roc td.key,
div.rocar td.key,
div.rocev td.key,
div.scr td.key,
div.scrar td.key,
div.ccni td.key,
div.ccniar td.key,
div.bod td.key,
div.sir td.key,
div.inpi td.key,
div.inpibil td.key,
div.rne td.key,
div.rnebs td.key,
div.pees td.key,
div.cvrcom td.key,
div.cvrpc td.key,
div.cvrreg td.key,
div.dkcom td.key,
div.dkper td.key,
div.nocom td.key,
div.noper td.key,
div.ccrcom td.key,
div.ccrrol td.key,
div.secom td.key,
div.seper td.key,
div.epo td.key,
div.euipotm td.key,
div.eutr td.key,
div.bkarta td.key,
div.radon td.key {
  font-weight: bold;
  width: 25%;
}
div.zefix td p,
div.dm td p,
div.lei td p,
div.fk td p,
div.dp td p,
div.hsk td p,
div.chp td p,
div.psc td p,
div.ukri td p,
div.ukrlp td p,
div.roc td p,
div.rocar td p,
div.rocev td p,
div.scr td p,
div.scrar td p,
div.ccni td p,
div.ccniar td p,
div.bod td p,
div.sir td p,
div.inpi td p,
div.inpibil td p,
div.rne td p,
div.rnebs td p,
div.pees td p,
div.cvrcom td p,
div.cvrpc td p,
div.cvrreg td p,
div.dkcom td p,
div.dkper td p,
div.nocom td p,
div.noper td p,
div.ccrcom td p,
div.ccrrol td p,
div.secom td p,
div.seper td p,
div.epo td p,
div.euipotm td p,
div.eutr td p,
div.bkarta td p,
div.radon td p {
  margin-top: 0;
  margin-bottom: 0;
}
div.zefix img,
div.dm img,
div.lei img,
div.fk img,
div.dp img,
div.hsk img,
div.chp img,
div.psc img,
div.ukri img,
div.ukrlp img,
div.roc img,
div.rocar img,
div.rocev img,
div.scr img,
div.scrar img,
div.ccni img,
div.ccniar img,
div.bod img,
div.sir img,
div.inpi img,
div.inpibil img,
div.rne img,
div.rnebs img,
div.pees img,
div.cvrcom img,
div.cvrpc img,
div.cvrreg img,
div.dkcom img,
div.dkper img,
div.nocom img,
div.noper img,
div.ccrcom img,
div.ccrrol img,
div.secom img,
div.seper img,
div.epo img,
div.euipotm img,
div.eutr img,
div.bkarta img,
div.radon img {
  display: block !important;
}
div.rne p.warning {
  margin-top: 10px;
  font-weight: 700;
}
div.wd table {
  width: 100%;
  border-collapse: collapse;
}
div.wd td {
  border-style: dotted;
  border-width: thin;
  padding-left: 15px;
  padding-right: 5px;
}
div.wd td:not(.key) {
  word-break: break-word;
}
div.wd td.key {
  font-weight: bold;
  width: 25%;
}
div.wd td p {
  margin-top: 0;
  margin-bottom: 0;
}
div.wd img {
  display: block !important;
}
div.wd th {
  text-align: center;
  font-weight: 700 !important;
}
div.wd th.key {
  text-align: left;
}
div.wd td.cell {
  text-align: right;
}
div.inpibil th,
div.rnebs th {
  text-align: center;
  font-weight: 700 !important;
}
div.inpibil th.key,
div.rnebs th.key {
  text-align: left;
}
div.inpibil td.cell,
div.rnebs td.cell {
  text-align: right;
}
div.dm img,
div.euipotm img {
  max-width: 80%;
}
table.hrcd {
  border-collapse: collapse;
}
.hrcd th {
  font-weight: bold !important;
  text-align: left;
  vertical-align: top;
  padding: 0.5em !important;
  border: 1px solid black;
}
.hrcd td {
  text-align: left;
  vertical-align: top;
  padding: 0.5em !important;
  border: 1px solid black;
}
div.euipods table {
  width: 100%;
}
div.euipods td.key {
  font-weight: bold;
  width: 25%;
}
div.euipods .image-display {
  display: block;
}
div.euipods .image-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 7px - 7px);
  height: 400px;
  margin: 7px;
  padding: 7px;
  background-color: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none;
}
div.euipods .image-wrapper img {
  display: block !important;
  max-width: 100%;
  max-height: 100%;
  border: none;
}
div.euipods .image-panel {
  display: flex;
  flex-wrap: wrap;
}
div.euipods .image-panel .image-wrapper {
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: none;
}
div.euipods .image-panel .image-wrapper:hover,
div.euipods .image-panel .image-wrapper:focus {
  border: 1px solid rgba(34, 36, 38, 0.35);
}
div.euipods .image-panel .image-wrapper.active,
div.euipods .image-panel .image-wrapper.active:hover {
  border: 1px solid #96c8da;
}
div.ktreg pre.kk-pre {
  white-space: pre-line;
  margin-top: 0;
}
table.bdl td:first-child {
  min-width: em;
}
.message.jobs-message {
  position: relative;
  background-image: url(https://welcome.northdata.com/hubfs/Jobs%20Banner%20Quentin%20og.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
}
.message.jobs-message ul {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-top: 0 !important;
}
.message.jobs-message li {
  margin-bottom: 0.3em !important;
}
.message.jobs-message p.close-message {
  display: inline;
}
.message.jobs-message p.close-message:hover {
  opacity: 1;
}
.green-overlay::before {
  content: "";
  background: rgba(102, 175, 139, 0.6);
  background: linear-gradient(90deg, rgba(102, 175, 139, 0.8) 0%, rgba(102, 175, 139, 0.1) 100%);
  transition: background 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.green-overlay:hover::before,
.green-overlay:focus::before {
  background: rgba(102, 175, 139, 0.75);
  background: linear-gradient(90deg, rgba(102, 175, 139, 0.9) 0%, rgba(102, 175, 139, 0.25) 100%);
}
.green-overlay > * {
  position: relative;
  color: white;
  z-index: 1;
}
.purple-overlay::before {
  content: "";
  background: rgba(172, 102, 175, 0.6);
  background: linear-gradient(90deg, rgba(172, 102, 175, 0.8) 0%, rgba(172, 102, 175, 0.1) 100%);
  transition: background 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.purple-overlay:hover::before,
.purple-overlay:focus::before {
  background: rgba(172, 102, 175, 0.75);
  background: linear-gradient(90deg, rgba(172, 102, 175, 0.9) 0%, rgba(172, 102, 175, 0.25) 100%);
}
.purple-overlay > * {
  position: relative;
  color: white;
  z-index: 1;
}
.message.dataservices-message {
  position: relative;
  background-image: url(https://welcome.northdata.com/hubfs/Data%20Services%20Banner%20Background%20Images/Data%20Services%20Banner%20Background%201.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
}
.horizontalBannerDefaults {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
}
.horizontalBannerDefaults p.close-message {
  display: inline;
}
.horizontalBannerDefaults p.close-message:hover {
  opacity: 1;
}
.message.newsletter-message {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
  background-image: url(https://welcome.northdata.com/hubfs/roman-kraft-_Zua2hyvTBk-unsplash.jpg) !important;
}
.message.newsletter-message p.close-message {
  display: inline;
}
.message.newsletter-message p.close-message:hover {
  opacity: 1;
}
.message.faq-message {
  align-items: center !important;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/banners/faq-banner.jpg) !important;
}
.message.faq-message p.close-message {
  display: inline;
}
.message.faq-message p.close-message:hover {
  opacity: 1;
}
.message.faq-message i {
  font-family: "Josefin Sans", serif !important;
  line-height: 1.2 !important;
  font-size: 3.5em !important;
}
.message.faq-message > * {
  color: white;
}
.message.faq-message p {
  margin: 0.5em 0 !important;
}
@media (min-width: 992px) {
  .message.faq-message p {
    margin: 0.5em 8em 0.5em 0 !important;
  }
}
.message.data-leader-message {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
  display: table !important;
  background-position: right !important;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/banners/data-and-ai-leaders-banner-bg.png) !important;
}
.message.data-leader-message p.close-message {
  display: inline;
}
.message.data-leader-message p.close-message:hover {
  opacity: 1;
}
.message.data-leader-message i {
  display: table-cell !important;
}
.message.data-leader-message .table.icon img {
  height: 1.5em;
  margin-right: 0.5em;
}
.message.data-leader-message::before {
  background: rgba(0, 156, 221, 0.9) !important;
}
.message.data-leader-message:hover::before,
.message.data-leader-message:focus::before {
  background: rgba(0, 156, 221, 0.75) !important;
}
.message.data-leader-message p {
  margin: 0.5em 0 !important;
}
@media (min-width: 992px) {
  .message.data-leader-message p {
    margin: 0.5em 8em 0.5em 0 !important;
  }
}
@media (max-width: 768px) {
  .message.data-leader-message i {
    display: inline-block !important;
    margin-bottom: 0.3em;
    height: auto;
  }
}
.message.csvexport-message {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/CSV-Export-banner-Background-img.jpg) !important;
}
.message.csvexport-message p.close-message {
  display: inline;
}
.message.csvexport-message p.close-message:hover {
  opacity: 1;
}
.message.csvexport-message .table.icon img {
  width: 65px;
}
.message.journalism-message {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
  background-position: right !important;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/investigative-journalism-banner.png) !important;
  background-color: #000;
  color: #fff;
}
.message.journalism-message p.close-message {
  display: inline;
}
.message.journalism-message p.close-message:hover {
  opacity: 1;
}
.message.journalism-message .table.icon img {
  width: 65px;
}
@media (any-pointer: fine) {
  .message.journalism-message:hover {
    background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/investigative-journalism-banner-hover.png) !important;
  }
}
.message.journalism-message::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/investigative-journalism-banner-hover.png) !important;
}
.blue-overlay::before {
  content: "";
  background: rgba(0, 115, 136, 0.6);
  transition: background 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.blue-overlay:hover::before,
.blue-overlay:focus::before {
  background: rgba(0, 115, 136, 0.75);
}
.blue-overlay > * {
  position: relative;
  color: white;
  z-index: 1;
}
sup .exposed {
  font-size: 1rem;
  margin-left: 0.3ex;
  color: #f2711c;
}
.w-100-pc {
  width: 100% !important;
}
.mw-600-px {
  max-width: 600px !important;
}
.mw-800-px {
  max-width: 800px !important;
}
.mw-832-px {
  max-width: 832px !important;
}
.overflow-auto {
  overflow: auto !important;
}
.mt-1-em {
  margin-top: 1em !important;
}
.mb-1-em {
  margin-bottom: 1em !important;
}
.mb-1-65-em {
  margin-bottom: 1.65em !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.m-10-px-a {
  margin: 10px auto !important;
}
/* The mtb-revert-child class is used in order to correct the
 margins of div-wrapped content that is included from HubSpot */
.mtb-revert-child h3:first-child {
  margin-top: revert !important;
}
.mtb-revert-child h4:first-child,
.mtb-revert-child h4.ui.header:first-child {
  margin-top: calc(2rem - 0.142857em) !important;
}
.mtb-revert-child p:last-child,
.mtb-revert-child p:first-child {
  margin-bottom: revert !important;
}
.display-block {
  display: block !important;
}
.fs-1-rm {
  font-size: 1rem;
}
.feature-watches-table {
  display: block !important;
}
.feature-watches-table tbody {
  width: 100%;
  display: table;
}
.feature-watches-table tbody td:last-child {
  text-align: right !important;
}
.video-container-large {
  width: 100%;
  max-width: 640px;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
}
.video-container-large img {
  height: 100%;
}
.vidyard-video .consent-disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 1rem;
  background-color: rgba(245, 245, 245, 0.8);
  border: 1px solid #f5f5f5;
}
@media (max-width: 767.98px) {
  .ui.ui.ui.ui.ui.table:not(.unstackable) > tbody > tr,
  .ui.ui.ui.ui.ui.table:not(.unstackable) > thead > tr,
  .ui.ui.ui.ui.ui.table:not(.unstackable) > tfoot > tr,
  .ui.ui.ui.ui.ui.table:not(.unstackable) > tr,
  .ui.ui.ui.ui.ui.table:not(.unstackable) > tbody > th,
  .ui.ui.ui.ui.ui.table:not(.unstackable) > thead > th,
  .ui.ui.ui.ui.ui.table:not(.unstackable) > tfoot > th,
  .ui.ui.ui.ui.ui.table:not(.unstackable) > th {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
}
/* removes the top margin for links inside tooltips (e.g. _coverage page) */
.ui.popup > a {
  margin-top: 0 !important;
}
table.dataTable.table thead th.sorting:after,
table.dataTable.table thead th.sorting_asc:after,
table.dataTable.table thead th.sorting_desc:after {
  color: #6e6e6e;
  top: 50%;
  transform: translateY(-50%);
}
table.ui.table.dataTable.dt-spacing {
  margin-top: calc(2rem + 2 * 0.678614em + 1.2142em + 2px);
  margin-bottom: calc(1rem + 2 * 0.928571em + 1em + 2px);
}
.ui.menu .paginate_button.previous,
.ui.menu .paginate_button.next {
  min-width: 0;
  padding-left: 0.9em;
  padding-right: 0.9em;
}
.ui.menu .paginate_button > i.icon {
  margin: 0;
}
table.static th.static-hidden,
table.static td.static-hidden {
  display: none;
}
@media only screen and (max-width: 767.98px) {
  .dataTables_wrapper > .ui.stackable.grid > .row > .column {
    padding: 0 !important;
  }
}
table.dataTable.table tr.selected {
  background-color: rgba(0, 115, 136, 0.1);
}
table.dataTable.table th > .ui.checkbox label:empty,
table.dataTable.table td > .ui.checkbox label:empty {
  padding: 0;
}
div.dataTables_wrapper div.row.unpadded:first-child {
  padding-bottom: 0 !important;
}
div.dataTables_wrapper div.row.unpadded:last-child {
  padding-top: 0 !important;
}
div.dataTables_wrapper div.row:not(.dt-table):first-child {
  padding-bottom: 0.5rem;
}
div.dataTables_wrapper div.row:not(.dt-table):last-child {
  padding-top: 0.5rem;
}
div.dataTables_wrapper .ui.grid .dt-header {
  background: #f9fafb;
  padding: 0.5em 0.7em;
  border: 1px solid rgba(34, 36, 38, 0.1);
  border-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}
div.dataTables_wrapper .ui.grid .dt-header .dataTables_length {
  margin-right: auto;
}
div.dataTables_wrapper .ui.grid .dt-header .ui.input,
div.dataTables_wrapper .ui.grid .dt-header .ui.button {
  margin: 0;
}
div.dataTables_wrapper .ui.grid .dt-pagination {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
div.dataTables_wrapper .ui.grid .dt-pagination .dataTables_info {
  padding: 0 2px;
}
div.dataTables_wrapper .ui.grid .dt-pagination .dataTables_paginate {
  margin-left: auto;
}
@media (max-width: 767.98px) {
  div.dataTables_wrapper .ui.grid .dt-pagination {
    flex-direction: column-reverse;
  }
  div.dataTables_wrapper .ui.grid .dt-pagination .dataTables_info {
    margin-left: auto;
  }
}
div.dataTables_wrapper .ui.grid .dt-header .button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: inherit;
}
.ranking-cell {
  display: grid;
  column-gap: 0.4em;
  grid-template-columns: auto 1fr;
}
.ranking-cell i {
  margin: 0;
}
#innovation-ranking-menu {
  margin-bottom: 14px;
  border-right-color: transparent;
  border-left-color: transparent;
}
.ui.grid.original-documents .column:last-of-type {
  display: flex;
  justify-content: center;
}
@media (min-width: 992px) {
  body[data-ads="false"] .ui.grid.original-documents .column:last-of-type {
    margin-top: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  [data-ads="true"] .ui.grid.original-documents .column {
    width: 100% !important;
  }
}
.shop-banner {
  position: relative;
  width: 36rem;
  max-width: 100%;
  height: 100%;
  padding: 3px;
  background-color: #007388;
  overflow: hidden;
}
.shop-banner > .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("/ui/v1-244-10/img/premium/banner.jpg");
  background-color: #007388;
  background-repeat: no-repeat;
  background-position: 50% 90%;
  background-size: cover;
  overflow: hidden;
  position: relative;
  height: 100%;
  padding: 0.5rem 2.5rem;
  font-size: 2.2rem;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
  transition: all 0.6s;
  transition-property: font-size;
}
.shop-banner > .content > span {
  left: 0;
  transform: translateX(0);
  transition: left 0.6s, transform 0.6s;
}
.shop-banner > .content > .ui.image {
  margin-right: -1rem;
  opacity: 1;
  left: 0;
  transform: translateX(0);
  transition: opacity 0.6s ease-in, left 0.6s ease-in, transform 0.1s ease-out;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  [data-ads="true"] .shop-banner {
    width: 28rem;
  }
}
@media (max-width: 767.98px) {
  .shop-banner {
    width: 28rem;
  }
}
@media (max-width: 450px) {
  .shop-banner {
    width: 20rem;
  }
  .shop-banner > .content {
    padding: 0.5rem 1.5rem;
  }
}
.shop-banner::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 60%;
  top: 20%;
  left: -50%;
  background: #fff;
  filter: blur(6px);
  -webkit-animation: rotating 8s linear infinite;
  -moz-animation: rotating 8s linear infinite;
  -ms-animation: rotating 8s linear infinite;
  -o-animation: rotating 8s linear infinite;
  animation: rotating 8s linear infinite;
  transition: opacity 0.6s ease-in-out;
}
a:hover .shop-banner .content,
a:focus .shop-banner .content {
  font-size: 2.8rem;
}
a:hover .shop-banner .content > span,
a:focus .shop-banner .content > span {
  left: 50%;
  transform: translateX(-50%);
}
a:hover .shop-banner .content > .ui.image,
a:focus .shop-banner .content > .ui.image {
  opacity: 0;
  left: 100%;
  transform: translateX(-50%);
}
a:hover .shop-banner::before,
a:focus .shop-banner::before {
  opacity: 0.5;
}
.shop-banner::after {
  content: "";
  position: absolute;
  top: -250%;
  left: -150%;
  width: 150%;
  height: 400%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.13) 33%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0) 100%);
}
.shop-banner.animated::after {
  opacity: 1;
  top: -50%;
  left: 120%;
  transition-property: left, top, opacity;
  transition-duration: 1s, 1s, 0.15s;
  transition-delay: 1s;
  transition-timing-function: ease;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.premium-services {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: #e6e5e9;
}
@media (max-width: 549.98px) {
  .premium-services {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .premium-services {
    margin: 0 -2rem;
  }
}
@media (max-width: 991.98px) {
  .premium-services {
    padding: 0 1rem;
  }
}
.premium-services__card.ui.card {
  margin: 0 2px;
  max-width: 300px;
  flex-grow: 1;
  text-wrap: balance;
}
.premium-services__card.ui.card.inverted {
  background-color: #007388;
}
.premium-services__card.ui.card.inverted,
.premium-services__card.ui.card.inverted .header,
.premium-services__card.ui.card.inverted .content,
.premium-services__card.ui.card.inverted .icon {
  color: #f5f5f5;
}
.premium-services__card.ui.card.inverted .content > .description {
  color: #e6e5e9;
}
.premium-services__card.ui.card.inverted .meta {
  color: #e6e5e9;
}
.premium-services__card.ui.card.inverted .extra {
  border-top-color: rgba(255 255 255 / 0.05) !important;
}
@media (max-width: 991.98px) {
  .premium-services__card.ui.card:not(.active) {
    display: none;
  }
}
.premium-services__card.ui.card > .content {
  flex-grow: 0;
}
.premium-services__card.ui.card > .content > .header {
  margin-top: 0.5rem;
}
.premium-services__card.ui.card > .content.extra {
  text-align: center;
}
.premium-services__card.ui.card > .content.title {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border-top: 0 !important;
}
.premium-services__card.ui.card > .content.fill {
  flex-grow: 1;
}
.premium-services__card.ui.card > .title + .extra.attribute {
  border-top: none !important;
  margin-top: 4rem;
}
.premium-services__card.ui.card > .title + .description {
  border-top: none !important;
  margin-top: calc(2rem + 11px);
  text-align: left;
  font-weight: 500;
}
.premium-services__card.ui.card > .title + .description p {
  margin-left: 0.5rem;
  text-wrap: initial;
}
.premium-services__card.ui.card > .floating.label {
  top: -1.25em;
  right: 50%;
  margin: 0;
}
.premium-services__card .features {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  margin: 0.75rem 0;
}
.premium-services__card .features .icon {
  margin: 0;
}
.premium-services__card .features > .all-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.premium-services__card .features > .all-features > div {
  display: flex;
  gap: inherit;
}
.premium-services__card .button {
  width: 100%;
}
.premium-services__card .attribute {
  display: flex;
  align-items: center;
}
.premium-services__card .attribute > .icon {
  flex-shrink: 0;
  margin: 0 1.25rem 0 0.5rem;
}
.premium-services__card .attribute > .content {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}
.premium-services__card .icon {
  width: 2rem;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);
}
.premium-services__card svg {
  fill: rgba(0, 0, 0, 0.6);
}
.premium-services__price {
  font-family: "Josefin Sans", serif;
  font-weight: bold;
  font-size: 2.6rem;
  margin: 0.5rem;
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .premium-services__hidden:not(.content),
  .premium-services__hidden > * {
    visibility: hidden;
  }
}
@media (max-width: 991.98px) {
  .premium-services__hidden {
    display: none;
  }
}
.premium-services__control.ui.card {
  align-self: start;
  margin: 0;
  margin-left: 2px;
  max-width: 200px;
}
@media (min-width: 992px) {
  .premium-services__control.ui.card {
    display: none !important;
  }
}
@media (max-width: 549.98px) {
  .premium-services__control.ui.card {
    max-width: unset;
    margin-bottom: 0.5rem;
    align-self: center;
  }
}
.premium-services__control.ui.card > .content > .header {
  margin-top: 0.5rem;
  text-align: center;
}
@media (max-width: 549.98px) {
  .premium-services__control-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
  }
}
.premium-services__control-buttons .ui.button {
  width: 100%;
  margin-bottom: 0.5rem;
}
@media (max-width: 549.98px) {
  .premium-services__control-buttons .ui.button {
    white-space: nowrap;
    overflow: hidden;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
.premium-services__control-buttons .ui.button.wide {
  grid-column: 1/-1;
}
@media only screen and (max-width: 991.98px) {
  .tablet.or.less.hidden {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  .mobile.hidden {
    display: none;
  }
}
.ranking-winners.ui.card .content:first-child {
  flex-grow: 0;
}
.ranking-winners.ui.card .content:first-child .header {
  margin-top: 1px;
}
.ranking-winners.ui.card .content i {
  margin-right: 14px;
}
.ranking-winners.ui.card .content .large.header {
  text-transform: uppercase;
}
.ui.popup > .content {
  font-weight: 300;
}
.ui.popup > .ui.divider {
  margin-top: 0.5em;
}
.ui.popup > a {
  display: inline-block;
  margin-top: 0.5em;
}
.ui.popup > a + a {
  margin-left: 0.5rem;
}
.ui.popup > a i.icon {
  margin: 0 0 0 0.25rem;
}
@keyframes blink {
  from {
    font-family: outline-icons;
    color: white;
  }
  to {
    font-family: icons;
    color: hsl(45, 100%, 85%);
  }
}
.outline.lightbulb.icon {
  animation-name: blink;
  animation-delay: 0.2s;
  animation-duration: 0.6s;
  animation-iteration-count: 2;
  animation-timing-function: linear;
  animation-play-state: paused;
}
.outline.lightbulb.icon.visible,
.outline.lightbulb.icon:hover {
  font-family: icons;
  color: hsl(45, 100%, 85%);
}
.leaflet-popup .leaflet-popup-tip {
  width: 14px;
  height: 14px;
}
.leaflet-popup .leaflet-popup-content-wrapper {
  border-radius: 0;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 0.833em 1em;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.4;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content a {
  color: #007388;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content a:hover {
  color: #006274;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .title {
  margin-right: 6px;
  font-weight: 500;
}
.leaflet-marker-icon.marker-cluster {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  line-height: 27px;
  text-align: center;
  border-radius: 100px;
  border: #a3b3c0 3px solid;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 13px;
}
.leaflet-marker-icon.marker-cluster:hover {
  border-color: #b2c0ca;
}
.leaflet-marker-icon.marker-cluster.active {
  border-color: #007388;
}
#search-map {
  height: 300px;
  margin-bottom: 0;
}
.horizontal-map {
  height: 350px;
  z-index: 5;
}
@media only screen and (min-width: 768px) {
  #search-map {
    height: 450px;
  }
}
@media only screen and (min-width: 992px) {
  #search-map {
    height: 600px;
  }
  .horizontal-map {
    height: 420px;
  }
}
@media only screen and (min-width: 1200px) {
  .horizontal-map {
    height: 500px;
  }
}
.nd-banner-link {
  text-decoration: none !important;
}
.nd-banner {
  display: flex;
  color: #ffffff;
  background-size: cover;
  font-family: "Lato", sans-serif;
  font-size: 1.1rem !important;
}
.nd-banner:hover .button {
  background-color: #ffffff !important;
}
.nd-banner:hover .box-center div img,
.nd-banner:hover .box-top .box-3 img {
  transform: scale(1.05);
}
.nd-banner .flexJustifyContent {
  display: flex;
  justify-content: center;
}
.nd-banner.horizontal {
  flex-direction: row;
  justify-content: space-evenly;
  height: 120px;
  min-height: 120px;
  min-width: 150px;
  background-image: url("/ui/v1-244-10/img/banner/dealfront/dealfront-banner-bg-horizontal.png");
}
.nd-banner.horizontal .box-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2%;
  gap: 5%;
  /* left logo container */
  /* left logo container */
}
.nd-banner.horizontal .box-left div:nth-child(1) {
  display: flex;
  justify-content: center;
}
.nd-banner.horizontal .box-left div:nth-child(1) img {
  max-height: 30px;
  width: 100%;
}
.nd-banner.horizontal .box-left div:nth-child(2) {
  display: flex;
  justify-content: center;
  text-align: center;
}
.nd-banner.horizontal .box-center {
  display: flex;
  align-items: center;
  padding: 0 2%;
}
.nd-banner.horizontal .box-center div img {
  max-height: 70px;
  width: 100%;
  transition: transform 0.2s;
}
.nd-banner.horizontal .box-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 2%;
  gap: 5%;
  /* right button claim */
  /* right button container */
}
.nd-banner.horizontal .box-right div:nth-child(1) {
  display: flex;
  justify-content: center;
  text-align: center;
}
.nd-banner.horizontal .box-right div:nth-child(2) {
  display: flex;
  justify-content: center;
}
.nd-banner.horizontal .box-right div:nth-child(2) .button {
  border-radius: 0.6rem;
  background-color: #ffc940;
  white-space: nowrap;
  color: #024ec1;
  padding: 0.5rem 1.2rem;
}
.nd-banner.horizontal .box-right div:nth-child(2) .button div {
  font-size: 1em;
}
@media screen and (max-width: 500px) {
  .nd-banner.horizontal {
    height: auto;
    flex-direction: column;
    padding: 5% 0;
  }
  .nd-banner.horizontal .box-left {
    gap: 0;
  }
  .nd-banner.horizontal .box-left div {
    margin: 2% 0;
  }
  .nd-banner.horizontal .box-left div img {
    max-height: unset !important;
    width: 55% !important;
  }
  .nd-banner.horizontal .box-center div {
    display: flex;
    justify-content: center;
    margin: 2% 0;
  }
  .nd-banner.horizontal .box-center div img {
    max-height: unset !important;
    width: 70% !important;
  }
  .nd-banner.horizontal .box-right {
    gap: 0;
  }
  .nd-banner.horizontal .box-right div {
    margin: 2% 0;
  }
}
.nd-banner.vertical {
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
  min-height: 600px;
  min-width: 100%;
  padding: 20% 5%;
  background-image: url("/ui/v1-244-10/img/banner/dealfront/dealfront-banner-bg-vertical.png");
}
.nd-banner.vertical .box-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20%;
}
.nd-banner.vertical .box-top .box-1 {
  display: flex;
  justify-content: center;
}
.nd-banner.vertical .box-top .box-1 img {
  width: 70%;
}
.nd-banner.vertical .box-top .box-2 {
  font-size: 1.1em;
  margin: 10%;
}
@media only screen and (max-width: 990px) {
  .nd-banner.vertical .box-top .box-2 {
    font-size: 0.8em;
  }
}
.nd-banner.vertical .box-top .box-3 {
  display: flex;
  justify-content: center;
}
.nd-banner.vertical .box-top .box-3 img {
  width: 90%;
  transition: transform 0.2s;
}
.nd-banner.vertical .box-bottom {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
}
.nd-banner.vertical .box-bottom .box-1 {
  font-size: 1.7em;
  line-height: 1.3em;
}
@media only screen and (max-width: 1200px) {
  .nd-banner.vertical .box-bottom .box-1 {
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 990px) {
  .nd-banner.vertical .box-bottom .box-1 {
    font-size: 0.8em;
  }
}
.nd-banner.vertical .box-bottom .box-2 {
  justify-content: center;
}
.nd-banner.vertical .box-bottom .box-2 .button {
  border-radius: 0.6rem;
  background-color: #ffc940;
  white-space: nowrap;
  color: #024ec1;
  padding: 1.2rem 1.2rem;
}
.nd-banner.vertical .box-bottom .box-2 .button div {
  font-size: 1.7em;
}
@media only screen and (max-width: 1200px) {
  .nd-banner.vertical .box-bottom .box-2 .button {
    padding: 0.5rem 0.5rem;
    margin: 0 !important;
  }
  .nd-banner.vertical .box-bottom .box-2 .button div {
    padding: 0 !important;
    font-size: 1.1rem !important;
  }
}
@media only screen and (max-width: 990px) {
  .nd-banner.vertical .box-bottom .box-2 .button {
    margin: 0 !important;
  }
  .nd-banner.vertical .box-bottom .box-2 .button div {
    padding: 0 !important;
    font-size: 0.8rem !important;
  }
}
.nd-banner.horizontal.creditsafe {
  background-image: url("/ui/v1-244-10/img/banner/creditsafe/creditsafe-banner-bg-horizontal.jpg");
  background-size: cover;
  background-position: center;
}
.nd-banner.vertical.creditsafe {
  background-image: url("/ui/v1-244-10/img/banner/creditsafe/creditsafe-banner-bg-vertical.jpg");
  background-size: cover;
  background-position: center;
}
.nd-banner.horizontal.creditsafe .box-right div:nth-child(2) .button,
.nd-banner.vertical.creditsafe .box-bottom div:nth-child(2) .button {
  background-color: #ee2e24;
  color: #fff;
}
.nd-banner.creditsafe:hover .button {
  background-color: #2c3e50 !important;
}
.nd-banner.horizontal.creditsafe .box-left div:nth-child(2) {
  text-align: left !important;
}
blockquote {
  font-size: 15px;
}
.quote-image {
  display: block;
  width: 180px;
  height: 248px;
  margin: 10px auto;
}
@media (min-width: 400px) {
  .quote-image {
    float: right;
    margin: 0 0 0 25px;
  }
}
[data-layout="netCompanyCount"] {
  display: flex;
  flex-direction: column;
}
[data-layout="netCompanyCount"] .legend {
  align-self: center;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}
[data-layout="netCompanyCount"] .legend > svg {
  margin-right: 8px;
}
[data-layout="netCompanyCount"] .legend > .definition {
  margin-right: 16px;
}
[data-layout="netCompanyCount"] .legend > .definition:last-child {
  margin-right: 0;
}
[data-layout="netCompanyCount"] .ui.label {
  padding: 4px 8px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 4px 8px;
  pointer-events: none;
  opacity: 0.9;
}
[data-layout="netCompanyCount"] .ui.label h4 {
  grid-column: 2 / span 2;
  margin: 0;
  font-size: 12px;
  margin: 4px 0;
}
[data-layout="netCompanyCount"] .ui.label svg {
  align-self: center;
}
[data-layout="netCompanyCount"] .ui.label .definition {
  grid-column: 2;
}
[data-layout="netCompanyCount"] .ui.label .definition:nth-of-type(2n) {
  margin-bottom: 4px;
}
[data-layout="netCompanyCount"] .ui.label .value {
  justify-self: end;
}
.ui.insolvencies.feed {
  display: flex;
  flex-wrap: wrap;
}
.ui.insolvencies.feed > .event {
  flex: 0 0 100%;
}
@media (min-width: 768px) {
  .ui.insolvencies.feed > .event {
    flex-basis: 50%;
  }
}
@media (min-width: 1200px) {
  .ui.insolvencies.feed > .event {
    flex-basis: 33.3%;
  }
}
.ui.insolvencies.feed > .event.active,
.ui.insolvencies.feed > .event:hover {
  background-color: #a3b3c0;
}
.ui.insolvencies.feed > .event > .label {
  margin: 0.25em 0 0.25em 0.25em;
}
.ui.insolvencies.feed > .event > .content .extra.text {
  margin-top: 0;
}
[data-layout="insolvenciesStaticMap"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 50%;
  float: left;
  margin-right: 16px;
}
